<template>
  <div class="FilesBox" @keyup.ctrl="test" @scroll="handleScroll()">
    <div class="nav_fileBox">
      <div
        class="title line"
        style="height: 58px; line-height: 58px; text-indent: 30px;"
      >
        <span
          class="iconfont icon-wenjiangui ant-menu-item-selected"
          style="font-size: 22px;"
        ></span>
        文件柜
      </div>
      <!-- <div class='create_file'> :style="{height: clientH}"
                <div class="file_button bgGlob" @click="createBtnFn('create')"><span class="iconfont icon-tianjiawenjian"></span> &nbsp; 新建</div>
                <ul class="create_tabs" v-show="create_tabs_bool">
                    <li class="line" @click="create_file_boxFn('create_file')"><span class="iconfont icon-biaoshilei_tianjiawenjianjia"></span>&nbsp; 新建文件夹 </li>
                    <li class="line"><span class="iconfont icon-wenjian1"></span>&nbsp; 上传文件
                        <input type='file' name="file" accept="." multiple='9' class="input_file" @change="handleChange" />
                    </li>
                    <li> <span class="iconfont icon-wenjianjia"></span>&nbsp; 上传文件夹
                        <input type="file" name="file" webkitdirectory  @click="fileClick()" class="input_file" @change="handleChangeFiles" />
                    </li>
                </ul>
      </div>-->
      <a-dropdown :trigger="['click']">
        <a-button
          type="primary"
          class="file_button"
          style="user-select: none;"
          @click="createBtnFn('create')"
        >
          <i class="iconfont icon-tianjiawenjian"></i> 新建
        </a-button>
        <a-menu slot="overlay" v-if="indexTab < 3">
          <a-menu-item
            @click="create_file_boxFn('create_file')"
            class="line"
            key="1"
          >
            <span
              class="iconfont icon-chuangjianwenjianjia"
              style="vertical-align: text-top;"
            ></span>
            新建文件夹
          </a-menu-item>
          <a-menu-item style="position: relative;" key="2">
            <i class="iconfont icon-wenjian1"></i>上传文件
            <input
              type="file"
              name="file"
              accept="."
              multiple
              class="input_file"
              @change="handleChange"
            />
          </a-menu-item>
          <a-menu-item style="position: relative;" key="3">
            <i class="iconfont icon-wenjianjia"></i>上传文件夹
            <input
              type="file"
              name="file"
              webkitdirectory
              class="input_file"
              @change="handleChangeFiles"
            />
          </a-menu-item>
        </a-menu>
      </a-dropdown>

      <ul class="navList">
        <li
          v-for="(item, i) in navArr"
          :key="i"
          :class="{
            lis: true,
            line: true,
            'ant-menu-item-selected': indexTab == i + 1,
          }"
        >
          <div style="position: relative;">
            <div
              @click="tabFn(i)"
              :class="{
                'border-r-4': true,
                'border-r-rgb': true,
                'border-r': indexTab == i + 1,
                bgHover: indexTab == i + 1,
              }"
            >
              <a-icon
                :type="
                  indexTab == i + 1 && tabBool ? 'caret-down' : 'caret-right'
                "
                v-show="i < (userInfo.partnerType == 1 ? 1 : 2)"
                style="margin-right: 6px; margin-left: -50px; color: #000;"
              />
              <span
                :class="item.icon"
                style="
                  margin-right: 0px;
                  font-size: 20px;
                  vertical-align: middle;
                "
              ></span>
              &nbsp; {{ item.title }}
            </div>
            <a-tree
              :loadData="ajaxFn"
              v-show="
                indexTab < (userInfo.partnerType == 1 ? 2 : 3) &&
                indexTab == i + 1 &&
                tabBool
              "
              @click="onSelect"
              :treeData="
                indexTab == 1 && userInfo.partnerType != 1
                  ? filePublicList
                  : fileMeList
              "
            />
            <!-- <a-tree
              v-show="(indexTab<3)&&(indexTab==(i+1))&&tabBool"
              :treeData="indexTab==1?filePublicList:fileMeList"
              showIcon
              @click="onSelect"
              style="position:absulte;top:40px;left:0px;margin-left: 20px;"
              :defaultSelectedKeys="defaultOpenFile"
            >
              <a-icon slot="smile" type="folder" style="margin-left: -12px" theme="filled" />
              <a-icon slot="meh" type="folder" style="margin-left: -12px" theme="filled" />
              <template slot="custom" class="border">
                <a-icon slot="meh" type="folder" style="margin-left: -12px" />
              </template>
            </a-tree> -->
          </div>
        </li>
      </ul>
    </div>
    <div class="right_box" ref="companyStyle">
      <div class="line" style="height: 58px;">
        <div class="input_box">
          <input
            type="text"
            v-model="searchVal"
            placeholder="搜索文件名或文件夹名称"
          />
          <a-icon type="search" @click="onSearchFile" class="btn" />
        </div>
      </div>
      <div class="file-title-box line">
        <div class style="height: 44px;">
          <span
            v-if="indexTab < 3"
            class="link-label"
            @click="
              ajaxFn_list(indexTab - 2 < 0 ? 0 : indexTab - 2, indexTab - 1)
            "
            >{{ navArr[indexTab - 1].title }}</span
          >
          <span
            @click="tabFn(indexTab - 1)"
            class="link-label"
            style="text-indent: 0px;"
            v-else
            >{{ navArr[indexTab - 1].title }}</span
          >
          <span
            v-if="navTitleArr.length < 4"
            v-for="(item, i) in navTitleArr"
            :key="i"
            @click="ajaxFn_list(item.Id, indexTab - 1)"
          >
            <a-icon
              type="right"
              style="
                text-indent: 0px;
                margin: 0px 10px;
                vertical-align: baseline;
              "
            />
            <span class="link-label">{{ item.Name }}</span>
          </span>
          <span
            v-if="navTitleArr.length > 3"
            @click="ajaxFn_list(navTitleArr[0].Id)"
          >
            <a-icon
              type="right"
              style="
                text-indent: 0px;
                margin: 0px 10px;
                vertical-align: baseline;
              "
            />
            <span class="link-label">{{ navTitleArr[0].Name }}</span>
          </span>
          <span v-if="navTitleArr.length > 3">
            <a-icon
              type="right"
              style="
                text-indent: 0px;
                margin: 0px 10px;
                vertical-align: baseline;
              "
            />
            <span class="link-label gengduo">
              <i
                class="iconfont icon-gengduo"
                @click="select_files_tab = true"
                style="margin-right: 0px;"
              ></i>
              <ul
                v-show="select_files_tab"
                class="create_tabs_right"
                style="
                  top: 46px;
                  left: -40px;
                  width: 300px;
                  text-indent: 16px;
                  text-align: left;
                "
                @mouseenter="test()"
                @mouseleave="select_files_tab = false"
              >
                <li
                  v-for="(item, index) in navTitleArr.slice(
                    1,
                    navTitleArr.length - 2
                  )"
                  :key="index"
                  @click="ajaxFn_list(item.Id)"
                >
                  <a-icon
                    type="folder"
                    theme="filled"
                    style="
                      margin-left: 19px;
                      font-size: 20px;
                      text-indent: -18px;
                      margin-right: 10px;
                      color: #666666;
                    "
                  />
                  {{ item.Name }}
                </li>
              </ul>
            </span>
          </span>
          <span
            v-if="navTitleArr.length > 3"
            v-for="(item, i) in navTitleArr.slice(
              navTitleArr.length - 2,
              navTitleArr.length
            )"
            :key="i"
            @click="ajaxFn_list(item.Id)"
          >
            <a-icon
              type="right"
              style="
                text-indent: 0px;
                margin: 0px 10px;
                vertical-align: baseline;
              "
            />
            <span class="link-label">{{ item.Name }}</span>
          </span>
        </div>

        <div style="font-size: 20px; position: relative;">
          <!-- <i class="iconfont icon-lianjie mr-2" title="复制链接" v-show="operationId.Id"></i> &&(operationId.OperateType=='edit')-->
          <i
            class="iconfont icon-gongxiang mr-2"
            title="共享"
            @click.stop="
              create_file_boxFn('public_file', operationId.Id, operationId)
            "
            v-show="operationId.Id && operationId.OperateType == 'edit'"
            style="font-size: 20px;"
          />
          <i
            class="iconfont icon-shanchu- mr-2"
            title="删除"
            v-if="indexTab < 4 && operationId.OperateType == 'edit'"
            @click="del_fileFn('删除', operationId.Id, operationId)"
            v-show="operationId.Id"
          ></i>
          <i
            class="iconfont icon-gengduo1"
            title="更多"
            v-show="operationId.Id && indexTab < 3"
            @click="operation_nav('three')"
          ></i>
          <img
            v-if="indexTab == 5"
            src="@/assets/images/clear.png"
            style="width: 20px;"
            title="清空回收站"
            @click.stop="del_fileFn('删除', 'all')"
          />
          <span
            v-show="operationId.Id && indexTab < 3"
            style="font-size: 22px; color: #999; margin: 0px 0px 0px 10px;"
            >|</span
          >
          <a-icon
            type="table"
            title="表格型"
            @click="tableBool = false"
            v-show="tableBool"
            class="mr-2"
          />
          <!-- <a-icon type="bars"  title="文件型" @click="tableBool=true" v-show="!tableBool" class="mr-2"/> -->
          <i
            class="iconfont icon-liebiao1 mr-2"
            style="margin-left: 16px;"
            title="文件型"
            @click="tableBool = true"
            v-show="!tableBool"
          ></i>
          <a-icon
            type="info-circle"
            @click="showDrawer2()"
            style="transform: rotate(180deg); cursor: pointer;"
          />
          <ul
            class="create_tabs2"
            style="right: 30px; top: 42px;"
            v-show="operation_nav_val == 'three' && operationId.OperateType"
            @mouseenter="test()"
            @mouseleave="cx()"
          >
            <li
              @click.stop="ear_lookFn('预览', operationId.Id, operationId)"
              v-if="
                operationId.FileUrl &&
                indexTab != 5 &&
                operationId.OperateType == 'edit'
              "
            >
              <i class="iconfont icon-yulan"></i>预览
            </li>
            <li
              @click.stop="
                create_file_boxFn('public_file', operationId.Id, operationId)
              "
              v-if="
                indexTab != 5 &&
                operationId.OperateType &&
                operationId.OperateType == 'edit'
              "
            >
              <i
                class="iconfont icon-gongxiang"
                style="font-size: 20px; vertical-align: middle;"
              ></i
              >共享
            </li>
            <!-- <li @click.stop="operation_item(item,operationId.Id,operationId)" > <i class="iconfont icon-lianjie"></i>复制共享链接</li> &&(operationId.OperateType=='edit') -->
            <li
              @click.stop="
                create_file_boxFn('move', operationId.Id, operationId)
              "
              v-if="
                indexTab < 3 &&
                operationId.OperateType &&
                operationId.OperateType == 'edit'
              "
            >
              <i class="iconfont icon-zhuanyi-"></i>移至
            </li>
            <li
              @click.stop="backPosFn('还原', operationId.Id, operationId)"
              v-if="(indexTab==5)"
            >
              <i class="iconfont icon-zhuanyi-"></i>还原
            </li>
            <li
              @click.stop="
                operation_item('查看详细信息', operationId.Id, operationId)
              "
              v-if="indexTab < 5 && operationId.OperateType"
            >
              <i class="iconfont icon-xinxi"></i>查看详细信息
            </li>
            <li
              @click.stop="
                starFn(
                  operationId.IsStar == 1 ? '取消星标' : '添加星标',
                  operationId.Id,
                  operationId
                )
              "
              v-if="indexTab != 5 && operationId.OperateType"
            >
              <i class="iconfont icon-guanzhu"></i>
              {{ operationId.IsStar == 1 ? '取消星标' : '添加星标' }}
            </li>
            <li
              @click.stop="
                create_file_boxFn('rename', operationId.Id, operationId)
              "
              v-if="
                indexTab < 3 &&
                operationId.OperateType &&
                operationId.OperateType == 'edit'
              "
            >
              <i class="iconfont icon-zhongmingming-"></i>重命名
            </li>
            <li
              @click.stop="
                create_file_boxFn('Jurisdiction', operationId.Id, operationId)
              "
              v-if="
                indexTab == 1 &&
                operationId.OperateType &&
                operationId.OperateType == 'edit'
              "
            >
              <i class="iconfont icon-icon--quanxian"></i>权限设置
            </li>
            <li
              @click.stop="operation_item('下载', operationId.Id, operationId)"
              v-if="
                indexTab < 3 && operationId.OperateType && operationId.FileUrl
              "
            >
              <a
                v-if="operationId.FileUrl"
                :href="operationId.FileUrl + '?attname=' + operationId.Name"
                style="color: #666666;"
                download="w3logo"
              >
                <i class="iconfont icon-xiazai-"></i> 下载
              </a>
              <span v-else> <i class="iconfont icon-xiazai-"></i>下载 </span>
            </li>
            <li
              @click.stop="del_fileFn('删除', operationId.Id, operationId)"
              v-if="
                indexTab < 3 &&
                operationId.OperateType &&
                operationId.OperateType == 'edit'
              "
            >
              <i class="iconfont icon-shanchu-"></i>删除
            </li>
          </ul>
        </div>
      </div>
      <div
        style="
          display: flex;
          position: relative;
          min-height: calc(100vh - 260px);
        "
        @contextmenu.prevent.stop="fileArea"
      >
        <div
          v-if="tableBool"
          :style="{
            padding: '20px 10px 20px 20px',
            'min-height': clientH,
          }"
          onselectstart="return false;"
          @click.stop="cancelFn()"
        >
          <p v-if="contentArr.length - imgContentArr.length > 0">文件夹</p>
          <div
            v-for="(list, j) in contentArr"
            :key="j"
            v-show="!list.FileUrl"
            :class="{
              'btn-file': true,
              bgHover: ar.indexOf(list.Id) > -1,
              'ant-menu-item-selected': ar.indexOf(list.Id) > -1,
              'border-files': ar.indexOf(list.Id) == -1,
              'border-line': ar.indexOf(list.Id) > -1,
              'ant-tree-node-selected': ar.indexOf(list.Id) > -1,
            }"
            @click.stop="operationFn(list, list.Id)"
          >
            <div
              @click.ctrl.stop="operationFn(list, list.Id, 'ctrl')"
              @contextmenu.prevent="
                operationFn_right('right', list.Id, 'files', list)
              "
            >
              <a-dropdown :trigger="['contextmenu']">
                <span style="user-select: none; width: 221px;" size="large">
                  <a-icon
                    type="folder"
                    theme="filled"
                    style="margin-left: 19px; font-size: 20px;"
                  />
                  <span class="files_name" :title="list.Name">{{
                    list.Name
                  }}</span>
                </span>
                <a-menu slot="overlay" style="width: 90%;">
                  <a-menu-item
                    @click="create_file_boxFn('public_file', list.Id, list)"
                    v-if="
                      indexTab != 3 &&
                      indexTab != 5 &&
                      list.OperateType == 'edit'
                    "
                  >
                    <i
                      class="iconfont icon-gongxiang"
                      style="font-size: 20px; vertical-align: middle;"
                    ></i
                    >共享
                  </a-menu-item>
                  <a-menu-item
                    @click="create_file_boxFn('move', list.Id, list)"
                    v-if="((indexTab<3)&&(arrContent.length<2)&&(list.OperateType=='edit'))"
                  >
                    <i class="iconfont icon-zhuanyi-"></i> 移至
                  </a-menu-item>
                  <a-menu-item
                    @click="backPosFn('还原', list.Id, list)"
                    v-if="indexTab == 5 && arrContent.length < 2"
                  >
                    <i class="iconfont icon-zhuanyi-"></i> 还原
                  </a-menu-item>
                  <a-menu-item
                    @click="operation_item('查看详细信息', list.Id, list)"
                    v-if="indexTab < 5 && arrContent.length < 2"
                  >
                    <i class="iconfont icon-xinxi"></i> 查看详细信息
                  </a-menu-item>
                  <a-menu-item
                    @click="
                      starFn(
                        list.IsStar == 1 ? '取消星标' : '添加星标',
                        list.Id,
                        list
                      )
                    "
                    v-if="indexTab != 5"
                  >
                    <i class="iconfont icon-guanzhu"></i>
                    {{ list.IsStar == 1 ? '取消星标' : '添加星标' }}
                  </a-menu-item>
                  <a-menu-item
                    @click="create_file_boxFn('rename', list.Id, list)"
                    v-if="
                      indexTab < 3 &&
                      arrContent.length < 2 &&
                      list.OperateType == 'edit'
                    "
                  >
                    <i class="iconfont icon-zhongmingming-"></i> 重命名
                  </a-menu-item>
                  <a-menu-item
                    @click="create_file_boxFn('Jurisdiction', list.Id, list)"
                    v-if="((indexTab==1)&&(arrContent.length<2)&&(list.OperateType=='edit'))"
                  >
                    <i class="iconfont icon-icon--quanxian"></i> 权限设置
                  </a-menu-item>
                  <a-menu-item
                    @click="operation_item('下载', list.Id, list)"
                    v-if="indexTab < 5 && arrContent.length < 2 && list.FileUrl"
                  >
                    <i class="iconfont icon-xiazai-"></i>
                    <span>下载</span>
                  </a-menu-item>
                  <a-menu-item
                    @click="del_fileFn('删除', list.Id, list)"
                    v-if="indexTab < 3 && list.OperateType == 'edit'"
                  >
                    <i class="iconfont icon-shanchu-"></i> 删除
                  </a-menu-item>
                  <a-menu-item
                    v-if="indexTab == 5"
                    @click="del_fileFn('删除', list.Id, list)"
                  >
                    <i class="iconfont icon-shanchu-"></i>清除
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>
          <p
            :style="{
              'margin-top':
                contentArr.length - imgContentArr.length > 0 ? '12px' : '0px',
            }"
            v-show="imgContentArr.length > 0"
            @click.stop="cancelFn()"
          >
            文件
          </p>
          <!-- 文件 -->
          <div
            style="display: inline;"
            v-for="list in imgContentArr"
            ref="listLi"
            @click.stop="operationFn(list, list.Id)"
          >
            <!-- :data-list="list.Id" -->
            <div
              @contextmenu.prevent="
                operationFn_right('right', list.Id, 'files', list)
              "
              :class="{
                'img-file': true,
                'ant-menu-item-selected': ar.indexOf(list.Id) > -1,
                'border-files': ar.indexOf(list.Id) == -1,
                'border-line': ar.indexOf(list.Id) > -1,
              }"
              @click.ctrl.stop="operationFn(list, list.Id, 'ctrl')"
            >
              <viewer
                v-if="
                  list.FileStatus == 1 &&
                  list.Id == lookId &&
                  list.Name.toLowerCase().indexOf('.svg') < 0
                "
                class="imgLook"
                :style="{
                  height: '216px',
                  width: dblCilckBool ? '236px' : '0',
                  position: 'absolute',
                }"
              >
                <!-- +'?imageMogr2/thumbnail/620x/strip/quality/50/format/webp' -->
                <img :src="list.FileUrl" style="width: 100%; height: 100%;" />
              </viewer>
              <a-dropdown
                :trigger="['contextmenu']"
                style="height: 216px; border-radius: 4px;"
              >
                <!--  -->
                <span class="box-file-look">
                  <div
                    style="height: 166px; overflow: hidden; position: relative;"
                  >
                    <div
                      v-if="(list.Name.toLowerCase().indexOf('.svg')>0)"
                      style="
                        position: absolute;
                        top: 0px;
                        height: 216px;
                        width: 100%;
                        left: 0px;
                      "
                    ></div>
                    <img
                      v-if="list.FileStatus == 4"
                      src="@/assets/images/rar.png"
                    />
                    <!-- < v-if="(list.FileStatus==1)&&(list.Name.indexOf('.svg')>0)"></> -->
                    <object
                      v-if="
                        list.FileStatus == 1 &&
                        list.Name.toLowerCase().indexOf('.svg') > -1
                      "
                      :data="list.FileUrl"
                      style="vertical-align: middle;"
                      width="234"
                      height="136"
                      type="image/svg+xml"
                      codebase="http://www.adobe.com/svg/viewer/imstall/"
                    />
                    <img
                      v-else-if="list.FileStatus == 1"
                      :src="list.FileUrl + '?imageView2/1/w/236'"
                    />
                    <!-- <iframe v-if="list.FileStatus==1" :src="list.FileUrl" width='206px' height='164px' style="overflow:hidden" frameborder='0'></iframe>  -->
                    <!-- <img src="https://smart-resource.sikegroup.com/FqY-L_h8xQ1pfbC8JhO8Kpygdxqx?imageView2/0/w/236"/> -->
                    <!-- <iframe
                      v-if="list.FileStatus==2&&(list.Name.indexOf('.pdf')>0)"
                      :src="list.FileUrl"
                      width="206px"
                      height="164px"
                      style="overflow:hidden"
                      frameborder="0"
                    ></iframe> -->
                    <i
                      v-else-if="
                        list.FileStatus == 2 &&
                        list.Name.toLowerCase().indexOf('.pdf') > -1
                      "
                      class="iconfont icon-tubiao_pdf"
                      style="color: red; font-size: 40px;"
                    ></i>
                    <!-- <i v-else-if="(list.FileStatus==2)&&(list.FileType.indexOf('text/')==0)" class="iconfont icon-tubiao_docx" style="color:#2F77F1;font-size:40px;" ></i> -->
                    <img
                      v-if="
                        list.FileStatus == 2 &&
                        list.FileType.indexOf('video/') == 0
                      "
                      :src="list.FileUrl + '?vframe/jpg/offset/1/h/300'"
                      style="vertical-align: middle;"
                    />
                    <audio
                      v-if="
                        list.FileStatus == 2 &&
                        list.FileType.indexOf('audio/') == 0
                      "
                      :src="list.FileUrl"
                      controls="controls"
                      style="width: 234px;"
                    >
                      您的浏览器不支持 audio 标签。
                    </audio>
                    <!-- <iframe
                      v-if="list.FileStatus==3"
                      :src="'https://view.officeapps.live.com/op/view.aspx?src='+list.FileUrl"
                      width="206px"
                      height="164px"
                      style="margin:6% 0%; overflow:hidden"
                      frameborder="0"
                    ></iframe> -->
                    <i
                      class="iconfont icon-tubiao_ppt"
                      v-if="
                        list.FileStatus == 3 &&
                        list.Name.toLowerCase().indexOf('.ppt') > 0
                      "
                      style="color: #fab800; font-size: 40px;"
                    ></i>
                    <i
                      class="iconfont icon-tubiao_docx"
                      v-if="
                        list.FileStatus == 3 &&
                        list.Name.toLowerCase().indexOf('.rtf') > 0
                      "
                      style="color: #2f77f1; font-size: 40px;"
                    ></i>
                    <i
                      class="iconfont icon-tubiao_xlsx"
                      v-if="
                        list.FileStatus == 3 &&
                        list.Name.toLowerCase().indexOf('.xls') > 0
                      "
                      style="color: #0f9d58; font-size: 40px;"
                    ></i>
                    <i
                      class="iconfont icon-tubiao_docx"
                      v-if="
                        list.FileStatus == 3 &&
                        list.Name.toLowerCase().indexOf('.doc') > 0
                      "
                      style="color: #2f77f1; font-size: 40px;"
                    ></i>

                    <i
                      v-if="
                        list.FileStatus == 0 ||
                        list.FileType.indexOf('text/') == 0
                      "
                      class="iconfont icon-tubiao_qita"
                      style="color: #888888; font-size: 40px;"
                    ></i>
                  </div>
                  <div class="info-file">
                    <i
                      v-if="list.FileStatus == 1"
                      class="iconfont icon-tubiao_tupian"
                      style="color: red;"
                    ></i>
                    <img
                      src="@/assets/images/rar.png"
                      v-else-if="list.FileStatus == 4"
                      style="
                        width: 22px;
                        margin-top: -6px;
                        text-indent: -30px;
                        margin-right: 10px;
                      "
                    />
                    <!-- <i v-else-if="(list.FileStatus==2)&&(list.FileType.indexOf('text/')==0)" class="iconfont icon-tubiao_docx" style="color:#2F77F1;" ></i> -->
                    <i
                      v-else-if="
                        list.FileStatus == 2 &&
                        list.FileType.indexOf('video/') == 0
                      "
                      class="iconfont icon-tubiao_shipin"
                      style="color: red;"
                    ></i>
                    <i
                      v-else-if="
                        list.FileStatus == 2 &&
                        list.FileType.indexOf('audio/') == 0
                      "
                      class="iconfont icon-tubiao_yinpin"
                      style="color: #2f77f1;"
                    ></i>
                    <i
                      v-else-if="
                        list.FileStatus == 2 &&
                        list.Name.toLowerCase().indexOf('.pdf') > -1
                      "
                      class="iconfont icon-tubiao_pdf"
                      style="color: red;"
                    ></i>
                    <i
                      class="iconfont icon-tubiao_ppt"
                      v-else-if="
                        list.FileStatus == 3 &&
                        list.Name.toLowerCase().indexOf('.ppt') > 0
                      "
                      style="color: #fab800;"
                    ></i>
                    <i
                      class="iconfont icon-tubiao_docx"
                      v-else-if="
                        list.FileStatus == 3 &&
                        list.Name.toLowerCase().indexOf('.rtf') > 0
                      "
                      style="color: #2f77f1;"
                    ></i>
                    <i
                      class="iconfont icon-tubiao_xlsx"
                      v-else-if="
                        list.FileStatus == 3 &&
                        list.Name.toLowerCase().indexOf('.xls') > 0
                      "
                      style="color: #0f9d58;"
                    ></i>
                    <i
                      class="iconfont icon-tubiao_docx"
                      v-else-if="
                        list.FileStatus == 3 &&
                        list.Name.toLowerCase().indexOf('.doc') > 0
                      "
                      style="color: #2f77f1;"
                    ></i>
                    <i
                      v-else
                      class="iconfont icon-tubiao_qita"
                      style="color: #888888;"
                    ></i>
                    <div class="name-file" :title="list.Name">
                      {{ list.Name }}
                    </div>
                  </div>
                </span>
                <a-menu slot="overlay" style="width: 120%; position: relative;">
                  <a-menu-item
                    @click="ear_lookFn('预览', list.Id, list)"
                    style="position: relative;"
                    v-if="list.FileUrl && list.FileStatus != 0 && indexTab != 5"
                  >
                    <i class="iconfont icon-yulan"></i>预览
                    <viewer
                      v-if="
                        list.FileStatus == 1 &&
                        list.Name.toLowerCase().indexOf('.svg') < 0
                      "
                      class="imgLook"
                      style="height: 36px; width: 150px; margin-top: -32px;"
                    >
                      <img
                        :src="
                          list.FileUrl +
                          '?imageMogr2/thumbnail/620x/strip/quality/50/format/webp'
                        "
                        style="width: 100%; height: 100%; opacity: 0;"
                      />
                    </viewer>
                  </a-menu-item>
                  <a-menu-item
                    @click="create_file_boxFn('public_file', list.Id, list)"
                    v-if="
                      indexTab != 3 &&
                      indexTab != 5 &&
                      list.OperateType == 'edit'
                    "
                  >
                    <i
                      class="iconfont icon-gongxiang"
                      style="font-size: 20px; vertical-align: middle;"
                    ></i
                    >共享
                  </a-menu-item>
                  <a-menu-item
                    @click="create_file_boxFn('move', list.Id, list)"
                    v-if="
                      indexTab < 3 &&
                      arrContent.length < 2 &&
                      list.OperateType == 'edit'
                    "
                  >
                    <i class="iconfont icon-zhuanyi-"></i> 移至
                  </a-menu-item>
                  <a-menu-item
                    @click="backPosFn('还原', list.Id, list)"
                    v-if="indexTab == 5 && arrContent.length < 2"
                  >
                    <i class="iconfont icon-zhuanyi-"></i> 还原
                  </a-menu-item>
                  <a-menu-item
                    @click="operation_item('查看详细信息', list.Id, list)"
                    v-if="indexTab < 5 && arrContent.length < 2"
                  >
                    <i class="iconfont icon-xinxi"></i> 查看详细信息
                  </a-menu-item>
                  <a-menu-item
                    @click="
                      starFn(
                        list.IsStar == 1 ? '取消星标' : '添加星标',
                        list.Id,
                        list
                      )
                    "
                    v-if="(indexTab!=5)"
                  >
                    <i class="iconfont icon-guanzhu"></i>
                    {{ list.IsStar == 1 ? '取消星标' : '添加星标' }}
                  </a-menu-item>
                  <a-menu-item
                    @click="create_file_boxFn('rename', list.Id, list)"
                    v-if="
                      indexTab < 3 &&
                      arrContent.length < 2 &&
                      list.OperateType == 'edit'
                    "
                  >
                    <i class="iconfont icon-zhongmingming-"></i> 重命名
                  </a-menu-item>
                  <a-menu-item
                    @click="create_file_boxFn('Jurisdiction', list.Id, list)"
                    v-if="
                      indexTab == 1 &&
                      arrContent.length < 2 &&
                      list.OperateType == 'edit'
                    "
                  >
                    <i class="iconfont icon-icon--quanxian"></i> 权限设置
                  </a-menu-item>
                  <a-menu-item v-if="indexTab < 5 && arrContent.length < 2">
                    <a
                      :href="list.FileUrl + '?attname=' + list.Name"
                      download="w3logo"
                    >
                      <i class="iconfont icon-xiazai-"></i> 下载
                    </a>
                  </a-menu-item>
                  <a-menu-item
                    @click="del_fileFn('删除', list.Id, list)"
                    v-if="indexTab < 3 && list.OperateType == 'edit'"
                  >
                    <i class="iconfont icon-shanchu-"></i> 删除
                  </a-menu-item>
                  <a-menu-item
                    v-if="indexTab == 5"
                    @click="del_fileFn('删除', list.Id, list)"
                  >
                    <i class="iconfont icon-shanchu-"></i>清除
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </div>
          </div>

          <!--点空白创建文件-->
          <ul
            class="create_tabs_right"
            :style="positionMap"
            style="width: 200px; text-indent: 20px;"
            v-show="positionMapBool"
          >
            <li class="line">
              <span class="iconfont icon-chuangjianwenjianjia"></span>
              新建文件夹
              <input
                type="button"
                name="file"
                @click.stop="create_file_boxFn('create_file')"
                class="input_file"
              />
            </li>
            <li>
              <span class="iconfont icon-wenjian1"></span> 上传文件
              <input
                type="file"
                name="file"
                accept="."
                multiple
                class="input_file"
                @change="handleChange"
              />
            </li>
            <li>
              <span class="iconfont icon-wenjianjia"></span> 上传文件夹
              <input
                type="file"
                name="file"
                webkitdirectory
                class="input_file"
                @change="handleChangeFiles"
              />
            </li>
          </ul>
        </div>
        <table
          border="1"
          cellspacing="0"
          cellpadding="0"
          style="margin: 10px;"
          v-else
        >
          <thead>
            <tr>
              <th style="width:60px;">序号</th>
              <th>名称</th>
              <th style="width: 220px">创建修改时间 /创建人</th>
              <th style="width: 110px;">文件大小</th>
            </tr>
          </thead>
          <!-- <tbody @mousemove='mousemoveFn' @mousedown="mousedownFn" @mouseup="mouseupFn" @mouseleave="mouseleaveFn" style="position:relative;"> -->
          <tbody style="position: relative;">
            <!-- class="am-active"class="active" @click="tableFn(i+1)"  @dblclick="openTable(i)" @click.stop="operationFn(list,list.Id)"  -->
            <!-- v-if="(list.FileStatus==1)&&(list.Id==lookId)"  -->
            <tr
              v-for="(list, index) in contentArr"
              ref="listLi"
              :data-list="list.Id"
              :key="index"
              :class="{
                'ant-menu-item-selected': ar.indexOf(list.Id) > -1,
                'border-line': ar.indexOf(list.Id) > -1,
              }"
              @click.stop="operationFn(list, list.Id)"
              @contextmenu.prevent.stop="
                operationFn_right('right', list.Id, 'files', list, $event)
              "
            >
              <!-- <viewer
                class="imgLook" v-if="(list.FileStatus==1)&&(list.Id==lookId)"
                :style="{width:'100%',height: dblCilckBool?'100%':'0px',}"
                >
                <img :src="list.FileUrl+'?imageMogr2/thumbnail/620x/strip/quality/50/format/webp'" style="width:100%;height:100%;" />
              </viewer> -->
              <!-- @click.stop="operationFn(list,list.Id)" @contextmenu.prevent="operationFn_right('right',list.Id,'files',list)" @click.ctrl.stop="operationFn_ctrl(list,list.Id,'ctrl')"> -->
              <td @click.ctrl.stop="operationFn(list, list.Id, 'ctrl')">
                {{(index+1)}}
                <viewer
                  v-if="list.FileStatus == 1 && list.Id == lookId"
                  class="imgLook"
                  :style="{
                    height: '100%',
                    width: dblCilckBool ? '100%' : '0',
                    position: 'fixed',
                  }"
                >
                  <img
                    :src="
                      list.FileUrl +
                      '?imageMogr2/thumbnail/620x/strip/quality/50/format/webp'
                    "
                    style="width: 100%; height: 100%;"
                  />
                </viewer>
              </td>
              <td @click.ctrl.stop="operationFn(list, list.Id, 'ctrl')">
                <viewer
                  v-if="list.FileStatus == 1 && list.Id == lookId"
                  class="imgLook"
                  :style="{
                    height: '100%',
                    width: dblCilckBool ? '100%' : '0',
                    position: 'fixed',
                  }"
                >
                  <img
                    :src="
                      list.FileUrl +
                      '?imageMogr2/thumbnail/620x/strip/quality/50/format/webp'
                    "
                    style="width: 100%; height: 100%;"
                  />
                </viewer>
                <span
                  style="
                    display: inline-block;
                    text-indent: 0px;
                    vertical-align: middle;
                    width: 100%;
                    height: 100%;
                  "
                >
                  <i
                    v-if="list.FileStatus == 1"
                    class="iconfont icon-tubiao_tupian"
                    style="color: red;"
                  ></i>
                  <img
                    src="@/assets/images/rar.png"
                    v-else-if="list.FileStatus == 4"
                    style="
                      width: 22px;
                      margin-top: -6px;
                      margin-left: 2px;
                      margin-right: 10px;
                    "
                  />
                  <i
                    v-else-if="
                      list.FileStatus == 2 &&
                      list.FileType.indexOf('video/') == 0
                    "
                    class="iconfont icon-tubiao_shipin"
                    style="color: red;"
                  ></i>
                  <i
                    v-else-if="
                      list.FileStatus == 2 &&
                      list.FileType.indexOf('audio/') == 0
                    "
                    class="iconfont icon-tubiao_yinpin"
                    style="color: #2f77f1;"
                  ></i>
                  <i
                    v-else-if="
                      list.FileStatus == 2 &&
                      list.Name.toLowerCase().indexOf('.pdf') > -1
                    "
                    class="iconfont icon-tubiao_pdf"
                    style="color: red;"
                  ></i>
                  <!-- <i v-else-if="(list.FileStatus==2)&&(list.FileType.indexOf('text/')==0)" class="iconfont icon-tubiao_docx" style="color:#2F77F1;" ></i> -->
                  <i
                    class="iconfont icon-tubiao_ppt"
                    v-else-if="
                      list.FileStatus == 3 &&
                      list.Name.toLowerCase().indexOf('.ppt') > 0
                    "
                    style="color: #fab800;"
                  ></i>
                  <!-- <i class="iconfont icon-tubiao_xlsx" v-else-if="(list.FileStatus==3)&&(list.Name.toLowerCase().indexOf('.xlsx')>0)" style="color: #0F9D58;"></i> -->
                  <i
                    class="iconfont icon-tubiao_docx"
                    v-else-if="
                      list.FileStatus == 3 &&
                      list.Name.toLowerCase().indexOf('.rtf') > 0
                    "
                    style="color: #2f77f1;"
                  ></i>
                  <!-- <i class="iconfont icon-tubiao_docx" v-else-if="(list.FileStatus==3)&&(list.Name.toLowerCase().indexOf('.docx')>0)" style="color:#2F77F1;"></i>
                    <i class="iconfont icon-tubiao_ppt"  v-else-if="(list.FileStatus==3)&&(list.Name.toLowerCase().indexOf('.pptx')>0)" style="color: #FAB800;"></i> -->
                  <i
                    class="iconfont icon-tubiao_xlsx"
                    v-else-if="
                      list.FileStatus == 3 &&
                      list.Name.toLowerCase().indexOf('.xls') > 0
                    "
                    style="color: #0f9d58;"
                  ></i>
                  <i
                    class="iconfont icon-tubiao_docx"
                    v-else-if="
                      list.FileStatus == 3 &&
                      list.Name.toLowerCase().indexOf('.doc') > 0
                    "
                    style="color: #2f77f1;"
                  ></i>
                  <!-- <i v-else-if="list.FileStatus==4" class="iconfont icon-yasuobao" style="color:#FAB800;"></i> -->
                  <i
                    v-else-if="list.FileStatus == 0"
                    class="iconfont icon-tubiao_qita"
                    style="color: #888888;"
                  ></i>
                  <i
                    v-else-if="!list.FileUrl"
                    class="iconfont icon-weibiaoti5"
                    style="
                      font-size: 22px;
                      margin-left: 4px;
                      margin-right: 8px;
                    "
                  ></i>
                  {{ list.Name }}
                </span>
              </td>
              <td @click.ctrl.stop="operationFn(list, list.Id, 'ctrl')">
                {{ list.CreateTime }}/{{ list.CreateUserName }}
                <viewer
                  v-if="list.FileStatus == 1 && list.Id == lookId"
                  class="imgLook"
                  :style="{
                    height: '100%',
                    width: dblCilckBool ? '100%' : '0',
                    position: 'fixed',
                  }"
                >
                  <img
                    :src="
                      list.FileUrl +
                      '?imageMogr2/thumbnail/620x/strip/quality/50/format/webp'
                    "
                    style="width: 100%; height: 100%;"
                  />
                </viewer>
              </td>
              <td @click.ctrl.stop="operationFn(list, list.Id, 'ctrl')">
                <span v-if="list.FileSize > 0"
                  >{{ list.FileSize | decimal(100) }}KB</span
                >
                <viewer
                  v-if="list.FileStatus == 1 && list.Id == lookId"
                  class="imgLook"
                  :style="{
                    height: '100%',
                    width: dblCilckBool ? '100%' : '0',
                    position: 'fixed',
                  }"
                >
                  <img
                    :src="
                      list.FileUrl +
                      '?imageMogr2/thumbnail/620x/strip/quality/50/format/webp'
                    "
                    style="width: 100%; height: 100%;"
                  />
                </viewer>
              </td>
              <ul
                class="create_tabs"
                :style="table_ul_pos"
                v-if="operationIndex == list.Id && table_ul_pos.top != '0px'"
              >
                <li
                  @click="ear_lookFn('预览', list.Id, list)"
                  v-if="list.FileUrl"
                  v-show="list.FileStatus != 1"
                >
                  <i class="iconfont icon-yulan"></i>预览
                </li>
                <!-- <li style="position:relative;">  -->
                <li
                  style="display: relative; height: 36px;"
                  @click.stop="test()"
                  v-if="list.FileStatus == 1"
                >
                  <span style="z-index: 0;"
                    ><i class="iconfont icon-yulan"></i>预览</span
                  >
                  <viewer
                    v-if="list.FileStatus == 1"
                    class="imgLook"
                    style="height: 36px; width: 150px; position: absolute;"
                  >
                    <img
                      :src="
                        list.FileUrl +
                        '?imageMogr2/thumbnail/620x/strip/quality/50/format/webp'
                      "
                      style="width: 100%; height: 100%;"
                    />
                  </viewer>
                </li>
                <!-- </li> -->
                <li
                  @click.stop="create_file_boxFn('public_file', list.Id, list)"
                  v-if="indexTab != 5 && list.OperateType == 'edit'"
                >
                  <i
                    class="iconfont icon-gongxiang"
                    style="font-size: 20px; vertical-align: middle;"
                  ></i
                  >共享
                </li>
                <!-- <li @click.stop="operation_item(item,list.Id,list)" > <i class="iconfont icon-lianjie"></i>复制共享链接</li>  -->
                <li
                  @click.stop="create_file_boxFn('move', list.Id, list)"
                  v-if="indexTab < 3 && list.OperateType == 'edit'"
                >
                  <i class="iconfont icon-zhuanyi-"></i>移至
                </li>
                <li
                  @click.stop="backPosFn('还原', list.Id, list)"
                  v-if="(indexTab==5)"
                >
                  <i class="iconfont icon-zhuanyi-"></i>还原
                </li>
                <li
                  @click.stop="operation_item('查看详细信息', list.Id, list)"
                  v-if="indexTab < 5"
                >
                  <i class="iconfont icon-xinxi"></i>查看详细信息
                </li>
                <li
                  @click.stop="
                    starFn(list.IsStar == 1 ? '取消星标' : '添加星标')
                  "
                  v-if="indexTab != 5"
                >
                  <i class="iconfont icon-guanzhu"></i>
                  {{ list.IsStar == 1 ? '取消星标' : '添加星标' }}
                </li>
                <li
                  @click.stop="create_file_boxFn('rename', list.Id, list)"
                  v-if="indexTab < 3 && list.OperateType == 'edit'"
                >
                  <i class="iconfont icon-zhongmingming-"></i>重命名
                </li>
                <li
                  @click.stop="create_file_boxFn('Jurisdiction', list.Id, list)"
                  v-if="indexTab == 1 && list.OperateType == 'edit'"
                >
                  <i class="iconfont icon-icon--quanxian"></i>权限设置
                </li>
                <li
                  @click.stop="operation_item('下载', list.Id, list)"
                  v-if="indexTab < 5 && list.FileUrl"
                >
                  <i class="iconfont icon-xiazai-"></i>下载
                </li>
                <li
                  @click.stop="del_fileFn('删除', list.Id, list)"
                  v-if="indexTab < 3 && list.OperateType == 'edit'"
                >
                  <i class="iconfont icon-shanchu-"></i>删除
                </li>
                <li
                  v-if="indexTab == 5"
                  @click="del_fileFn('删除', list.Id, list)"
                >
                  <i class="iconfont icon-shanchu-"></i>清除
                </li>
              </ul>
            </tr>
            <!-- typeFile&& -->
            <div
              id="moveSelected"
              :class="{ moveSelect: flag }"
              :style="stlObj"
            ></div>
          </tbody>
        </table>

        <!--详细信息 </div> ../assets/images/file_style.png-->
        <div
          class="file_details"
          v-show="visible2"
          :style="{ height: clientH, bottom: codeBool > 0 ? '36px' : '20px' }"
        >
          <div class="details_file_name title line">
            <span class="title_name">
              <a-icon
                type="folder"
                theme="filled"
                style="color: #666; margin-left: 20px;"
              />
              &nbsp; {{ detailsInfo.Name ? detailsInfo.Name : '公共文件柜' }}
            </span>
            <a-icon
              @click="visible2 = false"
              type="close-circle"
              style="
                font-size: 20px;
                color: #999;
                float: right;
                margin: 14px 10px 0px 0px;
              "
            />
          </div>
          <div class="details_file_tabs line">
            <div>
              <span
                :class="{
                  'bt-3': details_tabs == 1,
                  'ant-menu-item-selected': details_tabs == 1,
                }"
                @click="details_tabs_fn(1)"
                >详细信息</span
              >
            </div>
            <div>
              <span
                :class="{
                  'bt-3': details_tabs != 1,
                  'ant-menu-item-selected': details_tabs != 1,
                }"
                @click="details_tabs_fn(2)"
                >操作记录</span
              >
            </div>
          </div>
          <div
            class="details_info"
            style="height: calc(100% - 110px);"
            v-if="details_tabs == 1"
          >
            <div v-if="detailsInfo">
              <div
                class="imgBox line"
                style="max-width: 316px; max-height: 215px; overflow: hidden;"
              >
                <!-- <img :src="detailsInfo.FileUrl?detailsInfo.FileUrl:'@/assets/images/file_style.png'" > -->
                <img
                  v-if="detailsInfo.FileStatus == 4"
                  src="@/assets/images/rar.png"
                />
                <img
                  v-if="detailsInfo.FileStatus == 1"
                  :src="detailsInfo.FileUrl + '?imageView2/1/w/236'"
                />
                <!-- <iframe v-if="detailsInfo.FileStatus==1" :src="detailsInfo.FileUrl" width='206px' height='164px' style="overflow:hidden" frameborder='0'></iframe>  -->
                <!-- <img src="https://smart-resource.sikegroup.com/FqY-L_h8xQ1pfbC8JhO8Kpygdxqx?imageView2/0/w/236"/> -->
                <iframe
                  v-if="
                    detailsInfo.FileStatus == 2 &&
                    detailsInfo.Name.indexOf('.pdf') > 0
                  "
                  :src="detailsInfo.FileUrl"
                  width="206px"
                  height="164px"
                  style="overflow: hidden;"
                  frameborder="0"
                ></iframe>
                <!-- <iframe
                  v-if="detailsInfo.FileStatus==2&&(detailsInfo.FileType.indexOf('.text/')==0)"
                  :src="detailsInfo.FileUrl"
                  width="206px"
                  height="164px"
                  style="overflow:hidden"
                  frameborder="0"
                ></iframe> -->
                <video
                  v-if="
                    detailsInfo.FileStatus == 2 &&
                    detailsInfo.FileType.indexOf('video/') == 0
                  "
                  controls
                  style="width: 100%; max-height: 100%; vertical-align: middle;"
                >
                  <source :src="detailsInfo.FileUrl" type="video/mp4" />
                </video>
                <audio
                  v-if="
                    detailsInfo.FileStatus == 2 &&
                    detailsInfo.FileType.indexOf('audio/') == 0
                  "
                  :src="detailsInfo.FileUrl"
                  controls="controls"
                >
                  您的浏览器不支持 audio 标签。
                </audio>
                <iframe
                  v-if="detailsInfo.FileStatus == 3"
                  :src="
                    'https://view.officeapps.live.com/op/view.aspx?src=' +
                    detailsInfo.FileUrl
                  "
                  width="206px"
                  height="164px"
                  style="margin: 6% 0%; overflow: hidden;"
                  frameborder="0"
                ></iframe>
                <!-- <img v-if="detailsInfo.FileStatus==4" src="@/assets/images/zip.png"/> -->
                <img
                  v-if="(!detailsInfo.FileUrl)"
                  src="@/assets/images/file_style.png"
                />
                <i
                  v-if="detailsInfo.FileStatus == 0"
                  class="iconfont icon-tubiao_qita"
                  style="color: #888888; font-size: 40px;"
                ></i>
              </div>
              <div class="details_info_box">
                <div>类型</div>
                <div>
                  {{ detailsInfo.FileSize ? '文件' : '文件夹' }}({{
                    detailsInfo.Name
                  }})
                </div>
                <div>位置</div>
                <div>{{ detailsInfo.Url }}</div>
                <div v-show="detailsInfo.FileSize">大小</div>
                <div v-show="detailsInfo.FileSize">
                  {{ detailsInfo.FileSize | decimal(100) }}KB
                </div>
                <div
                  v-show="
                    detailsInfo.Url.indexOf('员工手册') !=
                    detailsInfo.Url.length - 4
                  "
                >
                  创建时间
                </div>
                <div
                  v-show="
                    detailsInfo.Url.indexOf('员工手册') !=
                    detailsInfo.Url.length - 4
                  "
                >
                  {{ detailsInfo.CreateTime }}({{ detailsInfo.CreateUserName }})
                </div>
                <div>备注描述</div>
                <div style="text-align: right;">
                  <a-icon
                    type="form"
                    style="font-size: 18px; cursor: pointer;"
                    @click="edit_infoFn()"
                  />
                </div>
                <a-textarea
                  v-model="edit_info_val"
                  :rows="4"
                  style="margin: 18px 0px 16px;"
                  v-if="edit_info_bool"
                />
                <div style="width: 280px; margin: 10px 0px 30px;" v-else>
                  {{ edit_info_val }}
                </div>
                <a-button
                  type="primary"
                  @click="edit_submit(detailsInfo)"
                  style="width: 200px; margin-left: 47px;"
                  v-show="edit_info_bool"
                  >提 交</a-button
                >
                <div
                  class="ant-button"
                  style="
                    width: 94%;
                    height: 38px;
                    background: #f1f1f1;
                    text-align: center;
                    line-height: 38px;
                    cursor: pointer;
                  "
                  v-clipboard:copy="detailsInfo.FileUrl"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                >
                  复制链接
                </div>
              </div>
            </div>
            <div style="text-align: center;" v-else>
              <img class="img_icon_max" src="@/assets/images/file_nomal.png" />
              <p style="color: #333333; margin-top: 20px;">
                选择文件或文件夹，即可查看其详细信息。
              </p>
            </div>
          </div>
          <div
            class="details_record"
            style="height: calc(100% - 110px);"
            v-else
          >
            <div v-if="detailsInfo" style="{height:100%,overflow: 'auto'}">
              <div v-for="item in detailsInfo.comment_list">
                <div class="details_record_box">
                  <div>{{ item.CreateTime }}</div>
                  <div style="display: flex; align-items: center;">
                    <img
                      :src="
                        item.CreateUserPic
                          ? item.CreateUserPic
                          : 'https://ss1.bdstatic.com/5eN1bjq8AAUYm2zgoY3K/r/www/cache/static/protocol/https/home/img/qrcode/zbios_09b6296.png'
                      "
                    />
                    <div style="display: inline-block; max-width: 230px;">
                      {{ item.Content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="text-align: center;" v-else>
              <img class="img_icon_max" src="@/assets/images/file_style.png" />
              <p style="color: #333333; margin-top: 20px;">
                选择文件或文件夹，即可查看其操作记录。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="mask" :style="backgroun" @click="maskFn" v-show="maskBool"></div> -->
    <!-- 侧边栏 -->
    <a-drawer
      placement="right"
      width="480px"
      :closable="false"
      @close="cancelFn()"
      :visible="visible1"
    >
      <div class="create_file_box" v-if="drawer_type == 'create_file'">
        <div class="drawer_file_name line">
          {{
            navTitleArr.length > 0
              ? navTitleArr[navTitleArr.length - 1].Name
              : navArr[indexTab - 1].title
          }}
        </div>
        <div class="drawer_file_input_box">
          <div style="margin: 10px 0px;">新建名称</div>
          <a-input-search
            placeholder="无标题文件夹"
            @search="onSearch"
            enterButton="确 认"
            size="large"
          />
          <span style="margin: 10px 0px 200px; display: inline-block;"
            >在"
            {{
              navTitleArr.length > 0
                ? navTitleArr[navTitleArr.length - 1].Name
                : navArr[indexTab - 1].title
            }}"中创建新的文件夹</span
          >
          <div class></div>
        </div>
      </div>
      <div class="create_file_box" v-else-if="drawer_type == 'move'">
        <!-- {{arrContent}} -->
        <div class="drawer_file_name line">
          {{ navArr[indexTab - 1].title }}
        </div>
        <div class="drawer_file_input_box">
          <!-- <ul class="navList">
                 <li v-for="(item,i) in navArr" :key="i" @click="tabFn(i)" :class="{bgHover: indexTab==i+1,'ant-menu-item-selected': indexTab==i+1}">
                  <div><a-icon type="caret-right" theme="filled"/> <a-icon type="folder" theme="filled" />&nbsp; {{item.title}}</div>
                   <!-- <span :class="item.icon"></span>
                    </li>
          </ul>-->
          <!-- <a-tree
            :treeData="indexTab==1?filePublicList:fileMeList"
            showIcon
            @click="onSelectMove"
            style="position:absulte;top:40px;left:0px"
            :defaultSelectedKeys="defaultOpenFile"
          >
            <a-icon slot="smile" type="folder" style="margin-left: -12px" theme="filled" />
            <a-icon slot="meh" type="folder" style="margin-left: -12px" theme="filled" />
            <template slot="custom">
              <a-icon slot="meh" type="folder" style="margin-left: -12px" />
            </template>
          </a-tree> -->
          <!-- <p>{{fileMeList}}1122</p>
          <p>{{treeData}}898898---{{indexTab}}--{{isme}}</p> -->
          <a-tree
            v-if="drawer_type == 'move'"
            :loadData="ajaxFn"
            @click="onSelectMove"
            :treeData="indexTab == 1 && isme != 1 ? filePublicList : fileMeList"
          />
        </div>
      </div>
      <div
        v-else-if="(drawer_type=='rename')"
        style="height: 500px; width: 100%;"
      >
        <div class="drawer_file_name line">{{ arrContent.Name }}重命名为：</div>
        <a-input-search
          v-model="editName"
          @search="edit_name_Fn()"
          enterButton="确 认"
          size="large"
        />
      </div>
      <div
        v-else-if="drawer_type == 'Jurisdiction'"
        style="padding-bottom: 50px;"
      >
        <div style="font-size: 18px;">权限设置</div>
        <div style="margin: 10px 0px;">只读</div>
        <div style="display: flex; margin-top: 20px;">
          <div style="width: 170px;">范围</div>
          <div style="width: 249px;">级别</div>
          <div style="width: 20px;"></div>
        </div>
        <div
          v-if="arrList.length > 0"
          style="margin-top: 10px; display: flex;"
          v-for="(item, k) in arrList"
          :key="k"
        >
          <a-tree-select
            style="width: 150px; margin-right: 10px;"
            :treeData="department_arr"
            :value="item.ManageAreaId ? item.ManageAreaId.split(',') : []"
            @change="onChangeval"
            @click="clickFn(k, 'read')"
            :disabled="item.bool == 'true'"
            searchPlaceholder="请选择部门"
          />
          <a-tree-select
            style="width: 240px;"
            :treeData="posts_type_arr"
            :value="item.TypeValue ? item.TypeValue.split(',') : []"
            :disabled="item.bool == 'true'"
            treeCheckable
            @click="clickFn(k, 'read')"
            @change="onChangepost"
            searchPlaceholder="请选择"
          />
          <i
            class="iconfont icon-shanchu-"
            @click="delArr(k)"
            style="font-size: 16px; margin-left: 5px;"
          ></i>
        </div>
        <div style="display: flex; margin: 16px 0px;">
          <a-tree-select
            style="width: 150px; margin-right: 10px;"
            :treeData="department_arr"
            :value="department_val"
            @change="onChange1"
            searchPlaceholder="请选择部门"
          />
          <a-tree-select
            style="width: 240px;"
            :treeData="posts_type_arr"
            :value="posts_val"
            treeCheckable
            @change="onChange2"
            searchPlaceholder="请选择"
          />
          <div style="width: 20px;"></div>
        </div>
        <a-button type="primary" @click="addItem()">添加</a-button>
        <!-- </div> -->
        <!-- <person v-bind="personObjRead" @getData="readChangePerson"></person> -->
        <div style="margin: 10px 0px;">编辑</div>
        <div style="display: flex; margin-top: 20px;">
          <div style="width: 170px;">范围</div>
          <div style="width: 249px;">级别</div>
          <div style="width: 20px;"></div>
        </div>
        <div
          v-if="arrList_edit.length > 0"
          style="margin-top: 10px; display: flex;"
          v-for="(item, k) in arrList_edit"
          :key="k"
        >
          <a-tree-select
            style="width: 150px; margin-right: 10px;"
            :treeData="department_arr"
            :value="item.ManageAreaId ? item.ManageAreaId.split(',') : []"
            @change="onChangeval_edit"
            searchPlaceholder="请选择部门"
            :disabled="item.bool == 'true'"
            @click="clickFn(k)"
          />
          <a-tree-select
            style="width: 240px;"
            :treeData="posts_type_arr"
            :value="item.TypeValue ? item.TypeValue.split(',') : []"
            treeCheckable
            @change="onChangepost_edit"
            searchPlaceholder="请选择"
            :disabled="item.bool == 'true'"
            @click="clickFn(k)"
          />
          <i
            class="iconfont icon-shanchu-"
            @click="delArr_edit(k)"
            style="font-size: 16px; margin-left: 5px;"
          ></i>
        </div>
        <div style="display: flex; margin: 16px 0px;">
          <a-tree-select
            style="width: 150px; margin-right: 10px;"
            :treeData="department_arr"
            :value="department_val_edit"
            @change="onChange1_edit"
            searchPlaceholder="请选择部门"
          />
          <a-tree-select
            style="width: 240px;"
            :treeData="posts_type_arr"
            :value="posts_val_edit"
            treeCheckable
            @change="onChange2_edit"
            searchPlaceholder="请选择"
          />
          <div style="width: 20px;"></div>
        </div>
        <a-button type="primary" @click="addItem_edit()">添加</a-button>
        <!-- </div> -->
        <!-- <person v-bind="personObjEdit" @getData="editChangePerson"></person> -->
      </div>
      <div
        v-else-if="drawer_type == 'public_file'"
        style="padding-bottom: 60px;"
      >
        <div style="font-size: 18px;">文件共享</div>
        <div style="margin: 10px 0px;">编辑</div>
        <person v-bind="personObjRead" @getData="readChangePerson"></person>
        <p style="margin-top: 18px; line-height: 22px;">{{ public_msg }}</p>
      </div>
      <div
        style="
          border-top: 1px solid #e0e1e2;
          height: 58px;
          line-height: 58px;
          position: absolute;
          bottom: 0px;
          left: 0px;
          width: 100%;
          background-color: #ffffff;
        "
        v-if="
          drawer_type == 'Jurisdiction' ||
          drawer_type == 'move' ||
          drawer_type == 'public_file'
        "
      >
        <a-button type="default" @click="cancelFn()" style="margin: 0px 30px;"
          >取 消</a-button
        >
        <a-button type="primary" @click="subBtn()">{{
          drawer_type == 'move' ? '移动到此处' : '确 定'
        }}</a-button>
      </div>
    </a-drawer>
    <!-- 预览功能 -->
    <div
      v-show="previewBool"
      style="
        position: fixed;
        top: 0px;
        width: 100%;
        bottom: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1000;
      "
    >
      <div class="look_box">
        <!-- <a-icon type="left-circle" v-if="prev_bool!=3" @click="ear_lookFn('prev')" class="prevNext btn_prev " />
        <a-icon type="right-circle" v-if="next_bool!=2" @click="ear_lookFn('next')" class="prevNext btn_next " />-->
        <!-- <div v-if="(img_info.FileStatus==1)" style="width:100%;max-height:100%;">
                    <viewer >
                        <img :src="img_info.FileUrl+'?imageMogr2/thumbnail/620x/strip/quality/50/format/webp'" style="max-width:100%;height:100%">
                    </viewer>
        </div>-->
        <img
          v-if="(img_info.FileStatus==4)"
          style="width: 40px;"
          src="@/assets/images/rar.png"
        />
        <object
          v-if="
            img_info.FileStatus == 1 &&
            img_info.Name.toLowerCase().indexOf('.svg') > -1
          "
          :data="img_info.FileUrl"
          width="600"
          height="600"
          style="vertical-align: middle;"
          type="image/svg+xml"
          codebase="http://www.adobe.com/svg/viewer/imstall/"
        />
        <div
          v-if="
            img_info.FileStatus == 2 && img_info.FileType.indexOf('video/') == 0
          "
        >
          <video
            controls
            style="max-height: 680px; max-width: 800px; vertical-align: middle;"
          >
            <source :src="img_info.FileUrl" type="video/mp4" />
            换浏览器
          </video>
        </div>
        <div
          v-if="
            img_info.FileStatus == 2 && img_info.FileType.indexOf('audio/') == 0
          "
        >
          <audio :src="img_info.FileUrl" controls="controls">
            您的浏览器不支持 audio 标签。
          </audio>
        </div>
        <iframe
          v-if="img_info.FileStatus == 2 && img_info.Name.indexOf('.pdf') > 0"
          :src="img_info.FileUrl"
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>
        <!-- <iframe
          v-if="img_info.FileStatus==2&&(img_info.FileType.indexOf('text/')==0)"
          :src="img_info.FileUrl"
          width="800px"
          height="76%"
          frameborder="0"
          style="margin-top:96px;background:#fff;"
        ></iframe> -->
        <iframe
          v-if="(img_info.FileStatus==3)"
          :src="
            'https://view.officeapps.live.com/op/view.aspx?src=' +
            img_info.FileUrl
          "
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>
        <!--rar -->
        <!-- <img v-if="(img_info.FileStatus==0)" src="@/assets/images/file_style.png" /> -->
        <i
          v-if="(img_info.FileStatus==0)"
          class="iconfont icon-tubiao_qita"
          style="color: #888888; font-size: 50px;"
        ></i>
      </div>
      <!-- 下载 删除 -->
      <!-- {background:((img_info.FileStatus==3&&img_info.Name.indexOf('.pptx')>0)||(img_info.Name.indexOf('.pdf')>0))?'#000':'#fff'} -->
      <div
        class="look_wrap ant-menu-item-selected"
        v-if="img_info"
        :style="{ background: bgColor }"
      >
        <div
          style="
            font-size: 16px;
            position: absolute;
            top: 16px;
            right: 24px;
            z-index: 1000;
          "
        >
          <a-dropdown>
            更多操作
            <a-icon type="down" />
            <a-menu slot="overlay">
              <a-menu-item>
                <a
                  :href="img_info.FileUrl + '?attname=' + img_info.Name"
                  download="w3logo"
                >
                  下载
                </a>
              </a-menu-item>
              <a-menu-item>
                <a
                  href="javascript:;"
                  @click="lookOpeation('star', img_info)"
                  >{{ img_info.IsStar == 1 ? '取消星标' : '添加星标' }}</a
                >
              </a-menu-item>
              <a-menu-item v-if="img_info.OperateType == 'edit'">
                <a href="javascript:;" @click="lookOpeation('del', img_info)"
                  >删 除</a
                >
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-icon
            type="close-circle"
            @click="lookFn()"
            style="margin-left: 20px; font-size: 26px;"
          />
        </div>
        <div class="look_top ant-menu-item-selected">{{ img_info.Name }}</div>
      </div>
    </div>
    <div v-show="spinning" style="position: fixed; left: 50%;">
      <a-spin style="margin: 150px 0%;">
        <a-icon
          slot="indicator"
          type="loading"
          :spinning="spinning"
          style="font-size: 44px; color: red;"
          spin
        />
      </a-spin>
    </div>
    <!-- 上传进度  -->
    <div class="progress-file" v-if="modal1Visible">
      <div
        style="
          background-color: #666666;
          height: 36px;
          line-height: 36px;
          color: #fff;
          padding: 0px 16px;
        "
      >
        上传进度
        <span style="margin-left: 132px; cursor: pointer;">
          <a-icon
            type="minus"
            @click="setProgressFn()"
            style="margin-right: 14px;"
          />
          <a-icon type="close" @click="setModal1Visible(false)" />
        </span>
      </div>
      <div class="progress-box" v-show="progressBool">
        <div v-if="file.length > 0" v-for="(list_file, i) in file" :key="i">
          <div
            style="
              height: 46px;
              line-height: 46px;
              width: 240px;
              overflow: hidden;
            "
          >
            <div style="width: 200px;" class="progress-over">
              <i
                v-if="list_file.type.indexOf('image/') == 0"
                class="iconfont icon-tubiao_tupian"
                style="color: red;"
              ></i>
              <img
                src="@/assets/images/rar.png"
                v-else-if="
                  list_file.type.indexOf('.zip') > 0 ||
                  list_file.type.indexOf('.rar') > 0
                "
                style="
                  width: 22px;
                  margin-top: -6px;
                  text-indent: -30px;
                  margin-right: 10px;
                "
              />
              <i
                v-else-if="(list_file.type.indexOf('video/')==0)"
                class="iconfont icon-tubiao_shipin"
                style="color: red;"
              ></i>
              <i
                v-else-if="(list_file.type.indexOf('audio/')==0)"
                class="iconfont icon-tubiao_yinpin"
                style="color: #2f77f1;"
              ></i>
              <i
                v-else-if="(list_file.type.indexOf('text/')==0)"
                class="iconfont icon-tubiao_docx"
                style="color: #2f77f1;"
              ></i>
              <i
                v-else-if="
                  list_file.name.toLowerCase().indexOf('.pdf') > -1 &&
                  list_file.type.indexOf('application/') == 0
                "
                class="iconfont icon-tubiao_pdf"
                style="color: red;"
              ></i>
              <i
                v-else-if="list_file.type.indexOf('application/') == 0"
                class="iconfont icon-tubiao_docx"
                style="color: #2f77f1;"
              ></i>
              <!-- <i v-else-if="list_file.FileStatus==4" class="iconfont icon-yasuobao" style="color:#FAB800;"></i> -->
              <i
                v-else
                class="iconfont icon-tubiao_qita"
                style="color: #888888;"
              ></i>
              <!-- <i v-else-if="!list_file.FileUrl" class="iconfont icon-weibiaoti5" style="font-size:22px;margin-left:4px;margin-right:8px;"></i> -->
              {{ list_file.name }}
            </div>
            <a-progress
              type="circle"
              :percent="0"
              :ref="list_file.name"
              :width="30"
              style="vertical-align: top; margin-top: 7px;"
            />
          </div>
        </div>
        <div
          style="
            height: 46px;
            line-height: 46px;
            width: 100%;
            overflow: hidden;
          "
          v-if="files.length > 0"
        >
          <div style="width: 210px; display: inline-block;">
            <div style="width: 94px;" class="progress-over">
              {{ filesName }}文件夹
            </div>
            <div
              style="margin-right: 2px; position: absolute;"
              class="progress-over"
            >
              已上传{{ upFilesNum }}项,共{{ files.length }}项
            </div>
          </div>
          <a-progress
            type="circle"
            :percent="Math.ceil((upFilesNum / files.length) * 100)"
            ref="cxcxcx"
            :width="30"
            style="vertical-align: top; margin-top: 7px;"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import person from '../../components/SelectPersonnel/SelectPersonnel' //选人
import Vue from 'vue'
import Viewer from 'v-viewer' //图片操作
import 'viewerjs/dist/viewer.css'
import VueClipboard from 'vue-clipboard2' //复制
Vue.use(VueClipboard)
Vue.use(Viewer)
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
function indexOfFn(val) {
  let index = val.toLowerCase().lastIndexOf('.')
  let last_value = val.substring(index + 1)
  return last_value
}
//判断文件类型格式
function fileUpPublicFn(list, qiniuFill, res) {
  let obj = {}
  let file_state = 0
  let value = indexOfFn(list.name)
  if (list.type.indexOf('image/') == 0) {
    file_state = 1
  } else if (
    list.type.indexOf('video/') == 0 ||
    list.type.indexOf('audio/') == 0 ||
    list.type.indexOf('text/') == 0
  ) {
    file_state = 2
  } else if (list.type.indexOf('application/') == 0) {
    let str = 'docx,pptx,xlsx,rtf,ppt,xls,doc'
    if (str.indexOf(value) != -1) {
      file_state = 3
    } else if (value == 'zip' || value == 'rar') {
      file_state = 4
    } else if (value == 'pdf') {
      file_state = 2
    } else {
    }
  } else {
    file_state = 0
  }
  obj.FileName = list.name
  obj.FileUrl = qiniuFill + res.data.key
  obj.FileType = list.type
  obj.FileSize = list.size
  obj.FileStatus = file_state
  obj.Path = list.webkitRelativePath ? list.webkitRelativePath : ''
  return obj
}
import MD5 from '@/assets/axios/md5'
function qiniuHash(type) {
  let timestamp = new Date().getTime()
  let num = Math.round(Math.random() * 10000000)
  let str = timestamp + '-' + num
  return MD5.md5(str) + '.' + type.toLowerCase()
}
import myAxios from 'axios'
import { setInterval } from 'timers'
import { isPrimitive } from 'util'
// const http = myAxios.create({
//     baseURL: 'https://smart.sikegroup.com',
//     withCredentials: false,
//     headers: {
//         'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
//     },
//     transformRequest: [function (data) {
//         let newData = '';
//         for (let k in data) {
//             if (data.hasOwnProperty(k) === true) {
//                 newData += encodeURIComponent(k) + '=' + encodeURIComponent(data[k]) + '&';
//             }
//         }
//         return newData;
//     }]
// });

var user = JSON.parse(window.sessionStorage.getItem('userInfo'))
//json數組去重
function uniqueArray(array, key) {
  let compact = []
  for (let i = 0; i < array.length; i++) {
    if (array[i] != undefined) {
      compact.push(array[i])
    }
  }
  array = []
  array = compact
  let result = [array[0]]
  for (let i = 1; i < array.length; i++) {
    let item = array[i]
    let repeat = false
    for (let j = 0; j < result.length; j++) {
      if (item[key] == result[j][key]) {
        repeat = true
        break
      }
    }
    if (!repeat) {
      result.push(item)
    }
  }
  return result
}
var tree_pos //存 this.pos;
export default {
  name: 'FilesBox',
  components: { person },
  data() {
    return {
      SHOW_PARENT,
      personObj: {
        rank: 1, //职级 0为不选，1为选
        nums: 10000, //选的数量
        department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        contacts: 'recent', //常用联系人 不要常用联系人传空''
        selectArr: [], //要传入的数据
      },
      // personObjRead: {
      //   rank: 1, //职级 0为不选，1为选
      //   nums: 10000, //选的数量
      //   department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
      //   contacts: "recent", //常用联系人 不要常用联系人传空''
      //   selectArr: [] //要传入的数据
      // },
      // personObjEdit: {
      //   rank: 1, //职级 0为不选，1为选
      //   nums: 10000, //选的数量
      //   department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
      //   contacts: "recent", //常用联系人 不要常用联系人传空''
      //   selectArr: [] //要传入的数据
      // },

      editPerson: [],
      readPerson: [],
      spinning: false,
      ar: [], //CTRL 多选
      clientH: '400px', //
      scrolltop: 237,
      clientH2: 700,
      positionMap: { left: '0px', top: '52px' }, //鼠标右击 点击空白区位置
      positionMapBool: false, //鼠标右击 点击空白区出现
      // input_edit_name_tabIndex: 0,//修改文件名的下标
      editName: '', //绑定要修改的文件名
      searchVal: '',
      navArr: [
        {
          //table
          title: '公共文件柜',
          icon: 'iconfont icon-buoumaotubiao25',
          url: '',
        },
        {
          title: '我的文件柜',
          icon: 'iconfont icon-wenjian',
          url: '',
        },
        {
          title: '与我共享',
          icon: 'iconfont icon-wj-gxwj',
          url: 'file/v1/user_share_list',
        },
        {
          title: '已加星标',
          icon: 'iconfont icon-guanzhu',
          url: 'file/v1/user_star_list',
        },
        // {
        //     title: '系统文件',
        //     icon: 'iconfont icon-screen',
        //     url: '',
        // },
        {
          title: '回收站',
          icon: 'iconfont icon-shanchu-',
          url: 'file/v1/recycle_bin',
        },
      ],
      navTitleArr: [], //文件柜的导航条右边的
      tableTab: 0,
      select_files_tab: false, //文件多出来的隐藏（...）
      maskBool: false, //遮罩层
      visible2: false, //侧边栏详情
      edit_info_bool: false, //文件详情是否编辑
      edit_info_val: '', //文件详情编辑的内容

      visible1: false, //侧边栏（移动，创建，）
      drawer_type: '', //侧边栏打开类型（移动，创建，重命名,权限）
      select_ment_arr: [], //已经选择的数据
      department_arr: [], //部门数据
      posts_type_arr: [], //岗位
      //权限设置
      posts_val_edit: [],
      posts_name_edit: [],
      department_val_edit: [],
      department_name_edit: [],
      arrList_edit: [],
      posts_val: [],
      posts_name: [],
      department_val: [],
      department_name: [],
      arrList: [],
      arrList_index: '',
      arrList_edit_index: '',

      indexTab: 1, //左边文件柜
      tabBool: true, //文件柜 收起or打开
      filePublicList: [], //文件柜数据（公共柜
      fileMeList: [], //文件柜数据 我的柜
      backgroun: {
        background: 'rgba(50, 50, 50, 0)',
      },
      tableBool: true, //table显示方式
      create_tabs_bool: false, //点击创建按钮
      operationIndex: 0, //文件夹点击右键 出现操作list
      // typeFile:'',//文件夹点击右键 文件类型 出现操作list
      operation_nav_val: '', //切换table icon的操作栏
      details_tabs: 1, //文件详情信息切换
      detailsInfo: '', //详情信息
      // uploadFill:[],//上传文件返回的路径
      uploadFillFiles: [], //上传文件夹返回的信息
      uptoken: '',
      file: [], //文件
      files: [], //文件夹
      filesName: '', //文件夹名称
      upFilesNum: 0, //已上传文件数量
      contentArr: [], //返回的文件夹list(点文件柜)
      imgContentArr: [], //返回的文件list(点文件柜)
      img_info: {}, //预览时单个文件信息
      img_index: 0,
      uploadFillFiles: [], //上传文件夹返回的信息
      modal1Visible: false, //上传文件的进度条
      progressBool: true, //进度隐藏/显示
      treeData: [],
      defaultOpenFile: ['0-0-0'],
      ctrlBool: false,
      detailsId: '', //查看详情的Id，放全局，侧边栏显示（也可以加载）
      dblCilckBool: false, //控制双击，单击事件
      arrContent: [], //多选文件以及文件夹
      operationId: 0, //当前操作的Id
      previewBool: false, //预览Bool
      next_bool: 1, //预览左右按钮 2,3隐藏
      prev_bool: 1,
      lookId: '', //要预览的Id
      file_state: 0, //文件那一类型（img/video/word等等）
      //拖动选中
      stlObj: { top: '10px', left: '10px' },
      flag: false, //是搜开启拖拽的标志
      oldLeft: 0, //鼠标按下时的left,top
      oldTop: 0,
      selectedList: [], //拖拽多选选中的块集合

      table_ul_pos: { top: '0px', left: '0px' }, //ul位置
      userInfo: '',
      bgColor: '', //控制预览时top背景色
      codeBool: 0,

      public_msg: '', //已共享的信息

      pos: [0],
      pos_arr: [],
      isme: 0, //加盟1 0直营
    }
  },
  created() {
    this.userInfo = JSON.parse(window.sessionStorage.getItem('userInfo'))
    if (sessionStorage.getItem('department_arr')) {
      this.department_arr = JSON.parse(sessionStorage.getItem('department_arr'))
    } else {
      this.$axios.get(8012, {}, (res) => {
        //改变权限设置方发
        if (!res.data) {
          return false
        }
        if (res.data.code == 1) {
          this.department_arr = res.data.data
          sessionStorage.setItem(
            'department_arr',
            JSON.stringify(res.data.data)
          )
        }
      })
    }
    if (!sessionStorage.getItem('posts_type_arr')) {
      this.$axios.get(8007, {}, (res) => {
        if (res.data.code == 1) {
          this.posts_type_arr = res.data.data
          window.sessionStorage.setItem(
            'posts_type_arr',
            JSON.stringify(res.data.data)
          )
        } else {
          this.$message.error('获取数据失败!')
        }
      })
    } else {
      this.posts_type_arr = JSON.parse(
        window.sessionStorage.getItem('posts_type_arr')
      )
    }
  },
  watch: {
    img_info(obj) {
      // console.log(obj);,ppt,xls,doc"
      try {
        if (obj.Name.indexOf('.rtf') > 0 || obj.Name.indexOf('.doc') > 0) {
          this.bgColor = '#fff'
        } else if (
          obj.Name.indexOf('.pdf') > 0 ||
          obj.Name.indexOf('.xls') > 0 ||
          obj.Name.indexOf('.ppt') > 0
        ) {
          this.bgColor = '#000'
        } else {
          this.bgColor = ''
        }
      } catch (err) {}
    },
  },
  mounted() {
    let that = this
    //  this.ajaxFn(0, 0);
    //  this.ajaxFn(1, 0);
    if (this.userInfo.partnerType == 1) {
      //加盟不让看公共文件夹
      that.isme = 1
      this.ajaxFn_list(0, 1)
      this.navArr.splice(0, 1)
    } else {
      this.ajaxFn(0, 0)
      this.ajaxFn_list(0)
    }
    this.ajaxFn(0, 1)
    this.clientH2 = this.$refs.companyStyle.clientWidth
    Viewer.setDefaults({
      Options: {
        inline: true,
        button: true,
        navbar: false,
        title: true,
        toolbar: false,
        tooltip: true,
        movable: true,
        zoomable: true,
        rotatable: true,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
        url: 'data-source',
      },
      hide: function (e) {
        // console.log(e.type,'图片隐藏完成');
        that.table_ul_pos.top = '0px'
        that.lookId = ''
      },
      hidden: function (e) {
        // console.log(e.type,'图片隐藏结束');
        that.table_ul_pos.top = '0px'
        that.lookId = ''
      },
    })
    if (this.$route.query.code) {
      this.codeBool = 100
    }
    let domHeight = document.documentElement.clientHeight
    this.clientH = domHeight - 256 + this.codeBool + 'px' // - 300 + "px";
    window.addEventListener('scroll', this.handleScroll, true) // 监听（绑定）滚轮滚动事件
  },

  methods: {
    clickFn(index, type) {
      if (type == 'read') {
        this.arrList_index = index
        for (let i = 0; i < this.arrList.length; i++) {
          if (index == i) {
            this.arrList[i].bool = false
          } else {
            this.arrList[i].bool = true
          }
        }
      } else {
        this.arrList_edit_index = index
        for (let i = 0; i < this.arrList_edit.length; i++) {
          if (index == i) {
            this.arrList_edit[i].bool = false
          } else {
            this.arrList_edit[i].bool = true
          }
        }
      }
    },
    delArr(index) {
      this.arrList.splice(index, 1)
    },
    delArr_edit(index) {
      this.arrList_edit.splice(index, 1)
    },
    addItem() {
      if (!this.department_val[0] && !this.posts_val[0]) {
        this.$message.error('不能都为空!!!')
        return false
      }
      let obj = { Type: 1 }
      ;(obj.TypeValue = this.posts_val[0] ? this.posts_val.join(',') : ''),
        (obj.TypeName = this.posts_name[0] ? this.posts_name.join(',') : ''),
        (obj.ManageAreaId = this.department_val[0] ? this.department_val : '')
      obj.ManageAreaName = this.department_name[0]
      obj.bool = 'true'
      this.arrList.push(obj)
      this.posts_val = []
      this.posts_name = []
      this.department_val = []
      this.department_name = []
      // console.log(this.arrList,555555)
    },
    addItem_edit() {
      if (!this.department_val_edit[0] && !this.posts_val_edit[0]) {
        this.$message.error('不能都为空!!!')
        return false
      }
      let obj = { Type: 1 }
      ;(obj.TypeValue = this.posts_val_edit[0]
        ? this.posts_val_edit.join(',')
        : ''),
        (obj.TypeName = this.posts_name_edit[0]
          ? this.posts_name_edit.join(',')
          : ''),
        (obj.ManageAreaId = this.department_val_edit[0]
          ? this.department_val_edit
          : '')
      obj.ManageAreaName = this.department_name_edit[0]
      obj.bool = 'true'
      this.arrList_edit.push(obj)
      this.posts_val_edit = []
      this.posts_name_edit = []
      this.department_val_edit = []
      this.department_name_edit = []
      // console.log(this.arrList_edit,555555)
    },

    //部门修改
    onChangeval() {
      let k = this.arrList_index
      this.arrList[k].ManageAreaId = arguments[0]
      this.arrList[k].ManageAreaName = arguments[1][0]
    },
    //岗位修改
    onChangepost() {
      // console.log(arguments);
      let k = this.arrList_index
      this.arrList[k].TypeValue = arguments[0] ? arguments[0].join(',') : ''
      this.arrList[k].TypeName = arguments[1] ? arguments[1].join(',') : ''
    },
    onChange1(val) {
      //部门
      this.department_val = val
      this.department_name = arguments[1]
    },
    onChange2(val) {
      this.posts_val = val
      this.posts_name = arguments[1]
    },
    //部门修改
    onChangeval_edit() {
      // console.log(val,k);
      // console.log(arguments)
      let k = this.arrList_edit_index
      this.arrList_edit[k].ManageAreaId = arguments[0]
      this.arrList_edit[k].ManageAreaName = arguments[1][0]
    },
    //岗位修改
    onChangepost_edit() {
      let k = this.arrList_edit_index
      this.arrList_edit[k].TypeValue = arguments[0]
        ? arguments[0].join(',')
        : ''
      this.arrList_edit[k].TypeName = arguments[1] ? arguments[1].join(',') : ''
    },
    onChange1_edit(val) {
      //部门
      this.department_val_edit = val
      this.department_name_edit = arguments[1]
    },
    onChange2_edit(val) {
      this.posts_val_edit = val
      this.posts_name_edit = arguments[1]
    },
    handleScroll() {
      let top = Math.ceil(document.querySelector('.contentLayout').scrollTop)
      if (!document.querySelector('.FilesBox')) {
        return false
      }
      let dom = document.querySelector('.FilesBox')
      let offsetTop = dom.offsetTop
      let domH = dom.clientHeight
      //  console.log(top,offsetTop,domH)
      if (top > 85) {
        this.scrolltop = 115
        this.clientH =
          document.documentElement.clientHeight -
          250 +
          this.codeBool +
          this.scrolltop +
          'px'
      } else {
        this.scrolltop = 237 - top
        this.clientH =
          document.documentElement.clientHeight - 258 + this.codeBool + 'px'
      }
    },

    cx() {
      this.operation_nav_val = ''
    },
    test() {
      console.log(63215641)
    },
    cancelFn() {
      //点击空白 取消选中
      this.ar = []
      this.operationId = {}
      this.visible1 = false
      this.lookId = ''
      // this.visible2 = false;
      this.arrContent = []
      this.positionMapBool = false
    },
    //点击文件柜（右边）获取当前点击的dom
    onSelect(selectedKeys, info) {
      console.log(
        'selected',
        selectedKeys,
        info,
        info.title,
        info.value,
        info.pos
      )
      this.pos = info.pos
      let arr = info.pos.split('-') //层级数组
      this.ajaxFn_list(info.dataRef.key, this.indexTab - 1)
    },
    //获取文件柜数据IsPrivate: 0公共 1为我的
    // ajaxFn(meType, Id) {
    //   console.log(this.indexTab)
    //   this.$axios.post(9999,{ UserId: this.userInfo.uid, Pid: Id, IsPrivate: meType },res=>{
    //     // console.log('cxcx',res)
    //       if (res.data.code == 0) {
    //       // console.log(this.fileMeList);
    //       } else if (res.data.code == 1) {
    //         if (meType != 1) {
    //           this.filePublicList = res.data.data;
    //           // this.ajaxFn_list(0);
    //         } else {
    //           this.fileMeList = res.data.data;
    //         }
    //       } else {
    //         this.$message.error("获取文件柜数据失败!");
    //       }
    //   });

    // },

    ajaxFn(treeNode, isme) {
      //  console.log(treeNode,treeNode.pos)
      let that = this
      let treeData
      let isPrimitiveIndex
      if (that.userInfo.partnerType == 1) {
        treeData = that.fileMeList
        isme = 1
      } else {
        if (isme) {
          isPrimitiveIndex = isme
          if (isme != 1) {
            treeData = that.filePublicList
          } else {
            treeData = that.fileMeList
          }
        } else {
          isPrimitiveIndex = that.indexTab - 1
          if (that.indexTab == 1) {
            treeData = that.filePublicList
          } else {
            treeData = that.fileMeList
          }
        }
        if (treeNode == 0 && isme == 0) {
          isPrimitiveIndex = 0
          that.filePublicList = []
          treeData = that.filePublicList
          // console.log(treeData,'cxxx1111')
        }
      }

      that.pos = that.pos_arr //记录that.pos_arr
      //  console.log(that.pos_arr,'pos_arr1');
      //  console.log('cxccx',that.filePublicList,that.indexTab,treeData,that.fileMeList);
      return new Promise((resolve) => {
        // if(treeNode==0){
        // }else if(!treeNode.dataRef){
        // }else if (treeNode.dataRef.children) {
        //   resolve();
        //   return;
        // }
        let id
        // if(typeof(treeNode)=='number'){
        //   id=0;
        //   treeData =[];
        // }else
        // console.log(that.pos)
        if (!treeNode.dataRef) {
          id = treeNode
        } else {
          if (isme == undefined) {
            this.pos = treeNode.pos.split('-')
            // console.log(this.pos);
            let str = treeNode.dataRef.key
            if (str.indexOf('-') > 0) {
              let index = str.indexOf('-')
              // console.log(str.slice(0,index),index)
              id = str.slice(0, index)
            } else {
              id = treeNode.dataRef.key
            }
          }
        }
        // console.log(that.pos)
        // console.log(treeData,'cxxx2222',id)
        that.$axios.post(
          9974,
          {
            UserId: user.uid,
            Pid: id ? id : 0,
            IsPrivate: this.userInfo.partnerType == 1 ? 1 : isPrimitiveIndex,
          },
          (res) => {
            //  console.log(res);
            try {
              if (res.data) {
                if (res.data.code != '1') {
                  // that.$message.error(res.data.msg);
                  return false
                }
              }
            } catch (error) {
              console.log('res.data错误')
            }

            // console.log(treeData,'cxxx23333',that.filePublicList)
            if (treeNode == 0) {
              treeData = res.data.data
            } else if (!treeNode.dataRef && treeNode != 0) {
              // console.log(this.pos,isme,isme==undefined)
              let arrpos
              if (Array.isArray(this.pos)) {
                arrpos = this.pos
              } else {
                arrpos = this.pos.split('-')
              }
              // console.log(arrpos)
              //如在最顶层添加文件夹或改名字 就直接刷新
              if (arrpos.length == 1) {
                this.ajaxFn(0, that.indexTab - 1)
              }
              if (arrpos.length == 2) {
                //如在第一层添加文件夹或改名字
                treeData[arrpos[1]].children = res.data.data
              }
              //如在第一层一下添加文件夹或改名字//删除文件
              if (arrpos.length > 2) {
                // console.log(treeData,arrpos,arrpos[1])
                let treeArr = treeData[arrpos[1]]
                // console.log(treeArr)
                let lit
                for (let i = 2; i < arrpos.length; i++) {
                  // console.log(treeArr)
                  treeArr = treeArr.children[arrpos[i]]
                }
                // console.log(treeArr)
                treeArr.children = res.data.data
              }
              //  console.log(treeData)
            } else {
              treeNode.dataRef.children = res.data.data
            }
            that.pos_arr = that.pos //记录that.pos_arr
            // console.log(that.pos_arr,'pos_arr');
            // console.log('ajaxFn',that.pos)
            // console.log(treeData,'cxxx23333',that.filePublicList)
            treeData = [...treeData]
            // console.log(treeData,that.filePublicList,that.fileMeList)
            if (this.userInfo.partnerType == 1) {
              that.fileMeList = treeData
              console.log(fileMeList, treeData)
            } else {
              isPrimitiveIndex != 1
                ? (that.filePublicList = treeData)
                : (that.fileMeList = treeData)
            }
            //  console.log(treeData,that.filePublicList,that.fileMeList)
            resolve()
          }
        )
      })
    },
    ajaxFn_list(Id, meType = 0, Name = 0) {
      // console.log(1111111111, Id, meType, Name,this.navTitleArr,this.pos,this.contentArr);
      Id ? Id : 0
      if (meType == 0 && this.userInfo.partnerType == 1) {
        meType = 1
      }
      //找到文件树里面的位置 pos = [0,2,1]
      // let nav_files_click=1;//判断是双击打开的文件夹（2），还是点击上面导航条打开（1）

      let that = this
      if (Id == 0) {
        that.pos = [0]
        console.log(12313223)
      }
      that.spinning = true
      this.visible2 = false
      that.ar = []
      that.arrContent = []
      let listInfo = that.contentArr
      this.$axios.post(
        9998,
        {
          UserId: that.userInfo.uid,
          Pid: Id == 1 ? 0 : Id,
          IsPrivate: meType > 1 ? 0 : meType,
          Name: Name,
        },
        (res) => {
          // console.log(res)
          //如果nav_files_click==1双击文件打开，否则点击navbar打开
          if (that.pos.indexOf('-') > -1) {
            that.pos = that.pos.split('-')
          }

          // for(let k=0;k<listInfo.length;k++){
          //   if(Id==listInfo[k].Id){
          //       that.pos.push(k);
          //       nav_files_click++;
          //       console.log(Id)
          //   }
          // }
          // if(nav_files_click==1){
          // let posLength = that.navTitleArr.length+1;
          // console.log(posLength)
          // if(posLength!=1){
          //   let a = that.pos.slice(0,posLength);
          //   console.log(a)
          //   that.pos= [];
          //   that.pos =a;
          // }
          // }(nav_files_click==2)&&
          if (that.navTitleArr.length < that.pos.length) {
            // console.log(that.navTitleArr,that.pos)
            that.pos.splice(that.navTitleArr.length - 1, that.pos.length - 2)
          }
          // console.log(nav_files_click)
          // console.log(that.pos)
          that.spinning = false
          that.contentArr = []
          that.imgContentArr = []
          that.navTitleArr = []
          if (this.userInfo.partnerType == 1 && that.indexTab) {
          } else {
            that.navTitleArr.push(that.navArr[that.indexTab - 1].title)
            that.navTitleArr = res.data.url_arr
          }

          if (res.data.code == 0) {
            that.$message.success(res.data.msg)
            return false
          }
          that.contentArr = res.data.data
          for (let i = 0; i < that.contentArr.length; i++) {
            if (that.contentArr[i].FileUrl) {
              that.imgContentArr.push(that.contentArr[i])
            }
          }
          //  that.indexTab==1?that.filePublicList=[]:that.fileMeList=[]
          // console.log(that.imgContentArr);
        }
      )
    },
    //details信息
    ajaxDetailsFn(id) {
      // if(!this.visible2){return false;}
      if (!id) {
        return false
      }
      let that = this
      that.detailsInfo = []
      that.spinning = true
      this.$axios.post(9997, { Id: id }, (res) => {
        that.spinning = false
        if (res.data.code == 0) {
          this.$message.error('详情信息为空')
          return false
        }
        if (res.data.code == 1) {
          that.spinning = false
          // this.contentArr=[];
          that.detailsInfo = res.data.data
          that.edit_info_val = that.detailsInfo.Content
          // that.navTitleArr = [];
          // that.navTitleArr = res.data.url_arr;
        }
      })
    },
    //修改(以及重命名)
    edit_details(obj) {
      let that = this
      that.spinning = true
      this.$axios.post(9996, obj, (res) => {
        //   console.log(res)
        that.edit_info_bool = false
        that.spinning = false
        if (res.data.code == 0) {
          that.$message.error(res.data.msg)
          return false
        }
        // that.input_edit_name_tabIndex = 0;//重命名成功重命名input消失
        let index
        let id
        if (this.navTitleArr.length == 0) {
          id = this.indexTab - 1
        } else {
          index = this.navTitleArr.length - 1
          id = this.navTitleArr[index].Id
        }
        that.ajaxFn_list(id, that.indexTab - 1, 0) //刷新
        that.ajaxFn(id, that.indexTab - 1)
        // if(res.data.code==0){this.$message.error('详情信息为空');}
        if (res.data.code == 1) {
          this.visible1 = false
          that.$message.success(res.data.msg)
        }
      })
    },
    fileArea(e) {
      //点击文件或文件夹空白区域
      this.visible2 = false
      // console.log("全局添加", e, e.offsetY, e.pageY, e.pageY - e.offsetY);
      // console.log('cx',e.path[1].className)
      // if(this.typeFile){return false;}//点击文件时夹不出来
      //判断是否点在文件夹上面
      // console.log(e)
      if (e.path[1].className) {
        // console.log("cx", e.path[1].className.indexOf("btn-file"));
        return false
      }
      this.visible2 = false
      if (e.toElement.localName != 'div') {
        return false
      }
      this.ar = []
      if (this.clientH2 - e.offsetX > 226) {
        this.positionMap.left = e.offsetX + 'px'
      } else {
        this.positionMap.left = e.offsetX - 226 + 'px'
      }
      if (e.pageY - e.offsetY < 130) {
        this.positionMap.top = e.offsetY - 130 + 'px'
      } else {
        this.positionMap.top = e.offsetY + 'px'
      }
      // this.maskBool = true;
      if (this.indexTab < 3) {
        this.positionMapBool = true
      }
      // console.log(this.positionMap);
    },
    handleChangeFiles(e) {
      //文件夹上传
      let that = this
      // console.log(e,22222222)
      this.create_tabs_bool = false
      that.visible2 = false
      this.files = e.target.files
      // console.log(e.target.files);
      let index = e.target.files[0].webkitRelativePath.indexOf('/')
      this.filesName = e.target.files[0].webkitRelativePath.substring(0, index)
      // console.log(this.filesName);
      that.upFilesNum = 0
      that.uptokenFn('files')
    },
    handleChange(e) {
      //文件上传
      // console.log(e.target.files)
      let that = this
      this.maskBool = false
      that.visible2 = false
      this.create_tabs_bool = false
      this.file = e.target.files
      that.uptokenFn('file')
    },
    uptokenFn(filetype) {
      //获取token
      // console.log(filetype)
      this.modal1Visible = true //进度条弹窗
      // console.log(this.indexTab)
      if (this.indexTab > 2) {
        return false
      }
      // console.log(filetype);
      let that = this
      that.uploadFillFiles = [] //文件夹
      let xhr = new XMLHttpRequest()
      xhr.open('get', 'https://smart.sikegroup.com/qiniuclient/smart_token')
      xhr.onreadystatechange = function (res) {
        if (
          xhr.readyState == 4 &&
          xhr.status == 200 &&
          xhr.responseText != ''
        ) {
          // console.log(res)
          let token = JSON.parse(res.target.response)
          // console.log(token.uptoken)
          if (token.uptoken) {
            that.uptoken = JSON.parse(res.target.response).uptoken
            that.qiuniuUp(filetype, 0)
          } else {
            that.$message.error('token获取失败!')
          }
        }
      }
      xhr.send()
    },
    async qiuniuUp(fileType, i) {
      // console.log('i',i)
      let that = this
      let Qiniu_UploadUrl = 'https://up-z2.qbox.me'
      let qiniuFill = 'https://smart-resource.sikegroup.com/'

      if (i < (fileType == 'file' ? that.file.length : that.files.length)) {
        const formData = new FormData()
        // let xhr = new XMLHttpRequest();
        // xhr.open('POST', Qiniu_UploadUrl, false);
        formData.append('token', that.uptoken)
        formData.append(
          'file',
          fileType == 'file' ? that.file[i] : that.files[i]
        )
        let t = fileType == 'file' ? that.file[i].name : that.files[i].name
        formData.append('chunks', 1)
        formData.append('chunk', 0)
        formData.append('key', qiniuHash(t.split('.')[1]))

        let config = {
          onUploadProgress: (progressEvent) => {
            let progressNum =
              ((progressEvent.loaded / progressEvent.total) * 100) | 0
            let refs =
              fileType == 'file'
                ? that.file[i].name
                : that.files[i].webkitRelativePath
            // console.log(refs)
            // console.log(that.$refs[refs])
            if (fileType == 'file') {
              //文件进度
              that.$refs[refs][0]._props.percent = progressNum
              // that.$refs[refs][0].parentElement.nextSibling.innerText = progressNum
              // console.log(progressNum);
            }
            //文件夹进度
          },
        }
        await myAxios.post(Qiniu_UploadUrl, formData, config).then((res) => {
          // console.log(
          //   res,
          //   "https://smart-resource.sikegroup.com/" + res.data.key
          // );
          if (res.status == 200) {
            if (fileType == 'file') {
              // console.log(that.file,i)
              that.uploadFillFiles.push(
                fileUpPublicFn(that.file[i], qiniuFill, res)
              )
            } else {
              // console.log(that.files[i].name)
              that.uploadFillFiles.push(
                fileUpPublicFn(that.files[i], qiniuFill, res)
              )
            }
          } else {
            that.$notification.open({
              message: `${
                fileType == 'file'
                  ? that.file[i].name
                  : that.files[i].webkitRelativePath
              }上传失败!`,
              icon: <a-icon type="close-circle" style="color:#e10000" />,
              duration: 0,
            })
          }
        })
        this.upFilesNum++
        that.qiuniuUp(fileType, i + 1)
      } else {
        // console.log("ajax=>sql");
        that.uploadFn(that.uploadFillFiles, fileType)
      }
    },

    //文件上传AJAX
    uploadFn(arrFile, fileType) {
      let that = this
      let id
      if (that.navTitleArr.length == 0) {
        id = 0 // that.indexTab-1;
      } else {
        let index = that.navTitleArr.length - 1
        id = that.navTitleArr[index].Id
        // console.log(that.navTitleArr,index,that.navTitleArr[index])
      }
      let numIndex
      if (this.userInfo.partnerType == 1) {
        numIndex = 1
      } else {
        numIndex = this.indexTab - 1
      }
      this.$axios.post(
        9993,
        {
          UserId: this.userInfo.uid,
          Pid: id,
          IsPrivate: numIndex,
          data: JSON.stringify(arrFile),
        },
        (res) => {
          // console.log("uploadFn", res);
          if (res.data.code == 1) {
            that.$notification.open({
              message: `${
                fileType == 'file'
                  ? that.file.length + '个文件'
                  : that.filesName + '文件夹'
              }上传成功`,
              icon: <a-icon type="check-circle" style="color:green" />,
              duration: 4,
            })
            that.modal1Visible = false
            that.ajaxFn_list(id, that.indexTab - 1)
          }
        }
      )
    },
    //进度条模态框
    setModal1Visible(modal1Visible) {
      this.modal1Visible = modal1Visible
    },
    //progressBool进度隐藏/显示
    setProgressFn() {
      this.progressBool = !this.progressBool
    },
    //文件详情
    showDrawer2() {
      this.visible2 = !this.visible2
      if (this.operationId.Id && this.visible2) {
        this.ajaxDetailsFn(this.operationId.Id)
      }
    },
    details_tabs_fn(i) {
      //切换
      this.details_tabs = i
    },
    edit_infoFn() {
      //描述编辑
      this.edit_info_bool = !this.edit_info_bool
    },
    edit_submit(info) {
      //描述编辑提交
      // console.log(this.edit_info_val);
      let obj = {
        UserId: this.userInfo.uid,
        Id: info.Id,
        Type: info.FileUrl ? 0 : 1,
        CategoryName: '备注',
        Category: 'Content',
        Value: this.edit_info_val,
      }
      // console.log(obj)
      this.edit_details(obj)
    },

    onSearchFile() {
      //搜索框
      // console.log(this.searchVal)
      if (this.searchVal) {
        let pid =
          this.navTitleArr.length > 0
            ? this.navTitleArr[this.navTitleArr.length - 1].Id
            : 0
        //   console.log(this.indexTab,this.navTitleArr)
        this.ajaxFn_list(pid, this.indexTab - 1, this.searchVal)
      }
    },
    tabFn(i, type) {
      this.pos = ''
      //文件柜侧边栏选项
      // console.log(this.indexTab, i + 1, this.tabBool);
      let that = this
      that.visible2 = false
      let numIndex
      this.userInfo.partnerType == 1 ? (numIndex = 1) : (numIndex = 2)
      if (type != 'del' && i < numIndex) {
        if (that.indexTab == i + 1 && that.tabBool) {
          that.tabBool = false
          return false
        }
      }
      that.tabBool = true
      // if(that.indexTab==(i+1)){return false;}
      that.indexTab = i + 1
      that.contentArr = []
      that.navTitleArr = []
      if (this.userInfo.partnerType != 1) {
        if (i == 1) {
          //如果点的是我的文件柜，把我的文件柜下的的数据显示在右边
          that.ajaxFn_list(0, 1)
          return false
        }
      }

      if (i == 0) {
        if (this.userInfo.partnerType == 1) {
          that.ajaxFn_list(0, 1)
        } else {
          that.ajaxFn_list(0, 0)
        }
        return false
      }
      if (i < numIndex) {
        return false
      } //如果点的是公共文件柜与我的文件柜，不再发送ajax

      that.spinning = true
      that.contentArr = []
      that.imgContentArr = []
      let codeUrl = 9983
      if (i == numIndex) {
        codeUrl = 9983 //file/v1/user_share_list
      } else if (i == numIndex + 1) {
        codeUrl = 9982 //file/v1/user_star_list
      } else if (i == numIndex + 2) {
        codeUrl = 9981 //file/v1/recycle_bin
      }
      that.$axios.post(codeUrl, { UserId: this.userInfo.uid }, (res) => {
        that.spinning = false
        // if(res.data.code==0){this.$message.error('详情信息为空');}
        if (res.data.code == 1) {
          that.contentArr = res.data.data
          that.navTitleArr = []
          // that.navTitleArr = res.data.url_arr ? res.data.url_arr : [];

          for (let i = 0; i < that.contentArr.length; i++) {
            if (that.contentArr[i].FileUrl) {
              that.imgContentArr.push(that.contentArr[i])
            }
          }
          // console.log( that.imgContentArr,that.contentArr)
          return false
        }
        if (res.data.code == 0) {
          that.$message.success('该文件夹为空!')
          return false
        }
        that.$message.error(res.data.msg)
      })
    },
    createBtnFn(type) {
      //点击新建按钮
      if (this.indexTab < 3) {
        this.create_tabs_bool = true
      } else {
        this.$message.error('只能在我的文件柜或公共文件柜操作!')
      }
    },
    maskFn() {
      //遮罩层
      this.maskBool = false
      this.create_tabs_bool = false //新建 上传文件，上传文件夹
      // this.typeFile = '';//控制operationArray:[]操作
      this.operation_nav_val = '' //切换table icon的操作栏
      this.positionMapBool = false //鼠标右键空白区域时
      this.arrContent = []
    },
    tableFn(i) {
      //点击table事件
      this.tableTab = i
      this.operationIndex = i
    },
    openTable(i) {
      //双击打开文件夹
      console.log('cx', i)
      this.operationIndex = '' //打开新的文件夹默认没有选
    },
    //按下CTRL选中(只在table用)
    operationFn_ctrl(list, i, type) {
      // console.log(type,'ctrl');
      let that = this
      // console.log(this.arrContent)
      // this.input_edit_name_tabIndex = 0;// 点击文件夹或文件时 重命名消失
      that.detailsId = list.Id
      that.operationIndex = i
      that.dblCilckBool = false
      // this.operationId=list;//移动文件夹用
      // console.log(this.operationId);//该文件信息
      if (that.indexTab > 2) {
        if (this.ar.length > 0) {
          return false
        }
      }
      that.ctrlBool = true
      that.ar.push(i)
      that.arrContent.push(list)
      that.tableTab = i
    },

    operationFn_right(list, id, type, rClick, e) {
      // console.log(id,rClick)
      this.table_ul_pos = { top: '0px', left: '0px' }
      if (e) {
        // console.log(e,e.clientX, e.clientY,e.screenY,e.screenX);
        // let pxT = e.clientY <270?e.clientY:(e.clientY -e.screenY+e.clientY);
        // let pxL = (e.screenX - e.clientX<180)&&(e.clientX>600)?(e.clientX-190):e.clientX
        this.table_ul_pos = {
          top: e.layerY + 'px',
          left: (e.target.cellIndex == 3 ? e.layerX - 170 : e.layerX) + 'px',
        }
      }
      if (rClick.FileStatus == 1) {
        this.lookId = rClick.Id
      } else {
        this.lookId = ''
      }
      // console.log("right", this.table_ul_pos);
      // console.log(list, id);
      let that = this
      this.operationId = rClick //移动文件夹用
      // that.typeFile = type;
      this.operationIndex = id
      that.dblCilckBool = false
      //   that.maskBool = true;
      // if(this.ar.length>1){
      // }else{this.ar = [];}
      if (this.ar.length == 0) {
        this.ar.push(id)
        this.arrContent.push(rClick)
      } else {
        //当右击在选中上面时
        let num = 0
        for (let j = 0; j < this.ar.length; j++) {
          if (id == this.ar[j]) {
            // console.log(this.arrContent, this.ar);
            num++
          }
        }
        if (num == 0) {
          this.ar = []
          this.arrContent = []
          this.ar.push(id)
          this.arrContent.push(rClick)
        }
        //当右击不在选中上面，清空所有。在添加当前选中
      }
      // console.log(this.arrContent, this.ar);
    },
    operationFn(list, i, type, rClick) {
      ///点击文件夹或文件时 type右击事件files ctrl事件‘ctrl’
      let that = this
      // console.log(list, i, type, rClick);
      this.table_ul_pos.top = '0px'
      that.lookId = list.Id
      if (that.dblCilckBool && that.operationIndex == i) {
        //判断双击打开文件夹
        that.visible2 = false
        //  console.log(that.navTitleArr,list)
        if (!list.FileUrl) {
          that.navTitleArr.push(list)
        }
        that.lookId = list.Id
        if (that.arrContent[that.arrContent.length - 1].FileUrl) {
          let targetObj = that.arrContent[that.arrContent.length - 1]
          // console.log(that.arrContent,targetObj)
          if (
            targetObj.FileStatus == 1 &&
            targetObj.Name.toLowerCase().indexOf('.svg') < 0
          ) {
          } else {
            that.ear_lookFn(targetObj, targetObj.Id, targetObj)
          }
        } else {
          that.ajaxFn_list(
            list.Id,
            that.indexTab - 1 > 1 ? list.IsPrivate : that.indexTab - 1
          )
        }
      }
      if (this.indexTab == 5) {
        this.dblCilckBool = false
      } else {
        if (list.FileStatus == 0 || list.FileStatus == 4) {
          this.dblCilckBool = false
        } else {
          this.dblCilckBool = true ///点击一次 点及二次
        }
      }
      this.operationIndex = i
      let tim = window.setInterval(function () {
        that.dblCilckBool = false
        clearInterval(tim)
      }, 1000)
      // this.input_edit_name_tabIndex = 0;// 点击文件夹或文件时 重命名消失
      this.operationId = list //移动文件夹用
      // console.log(this.operationId);//该文件信息
      if (type == 'ctrl') {
        if (this.indexTab > 2) {
          if (this.ar.length > 0) {
            return false
          }
        }
        // this.ctrlBool=true;
        // console.log(this.arrContent, this.ar);
      } else {
        this.ar = []
        this.arrContent = []
      }
      this.ar.push(i)
      this.arrContent.push(list)
      this.tableTab = i
      // console.log(this.ar, this.arrContent, this.imgContentArr,this.lookId);
      if (list != 'right') {
        if (this.visible2) {
          //详细信息侧边栏出栏才ajax
          this.ajaxDetailsFn(list.Id)
        }
      }
    },
    //文件创建或移动,重命名,权限
    create_file_boxFn(type, id, list) {
      //侧边弹出
      // console.log(type, id, list);
      // console.log(this.arrContent, this.ar); //ar会变空
      this.table_ul_pos.top = '0px' //(table控制ul操作显/隐)
      this.visible1 = true //侧边栏出来
      this.visible2 = false
      let that = this
      this.maskBool = false
      this.operation_nav_val = ''
      let num = 1
      if (that.arrContent.length != 0) {
        for (let i = 0; i < that.arrContent.length; i++) {
          if (that.arrContent[i].Id == id) {
            num++
          }
        }
      }

      if (num == 1) {
        that.arrContent = []
        that.arrContent.push(list)
      }
      // console.log(type, id, list);
      // console.log(that.arrContent);
      that.drawer_type = type
      if (type == 'create_file') {
        //创建文件柜
        that.create_tabs_bool = false
        that.positionMapBool = false
        // console.log(that.navTitleArr);
      }
      if (type == 'move') {
        //文件移动
        //  that.typeFile = '';
        that.operation_nav_val = ''
      }
      if (type == 'rename') {
        that.editName = list.Name
      }
      if (type == 'public_file') {
        that.$axios.post(9972, { Id: id }, (res) => {
          console.log(res.data)
          if (res.data.code == 1) {
            that.public_msg = res.data.msg
          }
        })
      }
      if (type == 'Jurisdiction') {
        that.spinning = true
        // this.personObjRead.selectData = [];
        // this.personObjEdit.selectData = [];
        this.$axios.post(9992, { UserId: this.userInfo.uid, Id: id }, (res) => {
          that.spinning = false
          if (res.data.code == 0) {
            // this.personObjRead.selectArr = [];
            // this.personObjEdit.selectArr = [];
            this.arrList = []
            this.arrList_edit = []
          } else if (res.data.code == 1) {
            // this.readPerson = res.data.date.read;
            // this.editPerson = res.data.date.edit;
            this.arrList = res.data.date.read
            this.arrList_edit = res.data.date.edit
            // this.personObjRead.selectArr = res.data.date.read;
            // this.personObjEdit.selectArr = res.data.date.edit;

            // console.log(
            //   this.personObjEdit.selectArr,
            //   this.personObjRead.selectArr
            // );
          } else {
            that.$message.error('获取权限数据失败!')
          }
        })
      }
    },
    onSelectMove(selectedKeys, info) {
      // console.log(info,selectedKeys)
      //选中节点
      this.operationId.value = info.dataRef.key
    },
    subBtn() {
      let that = this
      if (this.drawer_type == 'Jurisdiction') {
        // console.log("权限设置");
        if (that.department_val[0] || that.posts_val[0]) {
          let obj = { Type: 1 }
          ;(obj.TypeValue = that.posts_val[0] ? that.posts_val.join(',') : ''),
            (obj.TypeName = that.posts_name[0]
              ? that.posts_name.join(',')
              : ''),
            (obj.ManageAreaId = that.department_val[0]
              ? that.department_val
              : '')
          obj.ManageAreaName = that.department_name[0]
          obj.bool = 'true'
          that.arrList.push(obj)
        }
        if (that.department_val_edit[0] || that.posts_val_edit[0]) {
          let obj = { Type: 1 }
          ;(obj.TypeValue = that.posts_val_edit[0]
            ? that.posts_val_edit.join(',')
            : ''),
            (obj.TypeName = that.posts_name_edit[0]
              ? that.posts_name_edit.join(',')
              : ''),
            (obj.ManageAreaId = that.department_val_edit[0]
              ? that.department_val_edit
              : '')
          obj.ManageAreaName = that.department_name_edit[0]
          obj.bool = 'true'
          that.arrList_edit.push(obj)
        }
        that.spinning = true
        //    console.log( JSON.stringify(this.editPerson),JSON.stringify(this.readPerson))
        that.$axios.post(
          9991,
          {
            UserId: that.userInfo.uid,
            Id: that.arrContent[0].Id,
            Data: JSON.stringify({
              read: that.arrList,
              edit: that.arrList_edit,
            }),
          },
          (res) => {
            // console.log("xz", res);
            that.spinning = false
            if (res.data.code == 0) {
            } else if (res.data.code == 1) {
              that.visible1 = false
              that.$message.success(res.data.msg)
              // that.ajaxFn_list(0)
            } else {
              that.$message.error(res.data.msg)
            }
          }
        )
      }
      if (that.drawer_type == 'move') {
        //移动到节点下
        console.log(this.arrContent)
        // indexTab-2<0?0:indexTab-2  0公共文件柜1我的文件
        let obj = {
          UserId: this.userInfo.uid,
          Id: this.arrContent[0].Id,
          Type: this.operationId.FileUrl ? 0 : 1,
          CategoryName: '位置',
          Category: 'Pid',
          Value: this.operationId.value ? this.operationId.value : 0,
        }
        console.log(obj)
        that.edit_details(obj)
        this.visible1 = false //侧边栏消失
      }
      //共享
      if (this.drawer_type == 'public_file') {
        //获取人员
        this.public_fileFn()
      }
      that.drawer_type = ''
    },

    operation_item(obj, id, list) {
      //文件上的..  预览移除等等操作
      this.maskBool = false
      // this.typeFile = '';
      this.operation_nav_val = ''
      this.table_ul_pos.top = '0px' //(table控制ul操作显/隐)
      // console.log(obj, list,this.arrContent);
      // console.log(this.arrContent);
      if (obj == '查看详细信息') {
        this.visible2 = true
        this.ajaxDetailsFn(list.Id)
      }
      if (obj == '下载') {
        if (list.FileUrl) {
          return false
        } else {
          this.$axios.post(
            9990,
            { UserId: this.userInfo.uid, Id: id },
            (res) => {
              if (res.data.code == 0) {
                this.$message.error(res.data.msg)
              }
            }
          )
        }
      }
    },
    lookFn() {
      //预览隐藏
      this.previewBool = false
      this.img_info = []
    },
    ear_lookFn(obj, id, list) {
      // console.log(obj,'预览')
      // console.log(id,list)
      this.visible2 = false
      this.maskBool = false
      this.operation_nav_val = ''
      this.table_ul_pos.top = '0px' //(table控制ul操作显/隐)
      //   console.log(this.lookId,id)
      if (list.FileStatus == 1 && list.Name.toLowerCase().indexOf('.svg') < 0) {
        return false
      }

      if (obj == 'next' || obj == 'prev') {
        if (obj == 'next') {
          this.img_index++
          this.prev_bool = 1
          if (this.img_index == this.imgContentArr.length - 1) {
            this.next_bool = 2
          }
        }
        if (obj == 'prev') {
          this.img_index--
          this.next_bool = 1
          if (this.img_index == 0) {
            this.prev_bool = 3
          }
        }
      } else {
        for (let i = 0; i < this.imgContentArr.length; i++) {
          if (this.imgContentArr[i].Id == id) {
            this.img_index = i
          }
        }
        this.previewBool = true
        this.maskBool = false
        this.operation_nav_val = ''
        this.next_bool = 1
        this.prev_bool = 1
      }
      this.img_info = this.imgContentArr[this.img_index]
      // console.log(this.img_index, this.img_info)
    },

    public_fileFn() {
      //'共享')
      let fileArr = []
      let that = this
      // console.log('del',this.arrContent)
      if (this.arrContent.length > 1) {
        for (let i = 0; i < this.arrContent.length; i++) {
          let objarr = {}
          objarr.Id = this.arrContent[i].Id //;//(0是文件，1是文件夹)
          objarr.FileType = this.arrContent[i].FileUrl ? 0 : 1
          fileArr.push(objarr)
        }
      } else {
        let objarr = {}
        objarr.Id = this.arrContent[0].Id
        objarr.FileType = this.arrContent[0].FileUrl ? 0 : 1
        fileArr.push(objarr)
      }
      //  let personArr = []
      this.spinning = true
      // UserId:uid,Id:id,Data:{read:JSON.stringify(personArr),edit:JSON.stringify(personArr)}
      this.$axios.post(
        9989,
        {
          UserId: this.userInfo.uid,
          FileList: JSON.stringify(fileArr),
          PerimitData: JSON.stringify(that.readPerson),
        },
        (res) => {
          this.spinning = false
          if (res.data.code == 1) {
            that.readPerson = []
            that.editPerson = []
            this.maskBool = false
            this.visible1 = false
            this.operation_nav_val = ''
            this.$message.success(res.data.msg)
            return false
          }
          this.$message.error(res.data.msg)
        }
      )
    },
    del_fileFn(obj, Id, list) {
      // console.log('删除',obj);
      let that = this
      let fileArr = []
      that.maskBool = false
      that.visible2 = false
      that.operation_nav_val = ''
      this.table_ul_pos.top = '0px' //(table控制ul操作显/隐)
      that.arrContent.push(list)
      if (Id == 'all') {
        //  console.log('清空');
        if (that.contentArr.length < 1) {
          return false
        }
      } else {
        // console.log('del',that.arrContent)
        if (that.arrContent.length > 1) {
          for (let i = 0; i < that.arrContent.length; i++) {
            let objarr = {}
            objarr.Id = that.arrContent[i].Id //((0是文件，1是文件夹)
            objarr.FileType = that.arrContent[i].FileUrl ? 0 : 1
            fileArr.push(objarr)
          }
        } else {
          let objarr = {}
          // console.log(that.arrContent, "arrContent");
          objarr.Id = that.arrContent[0].Id
          objarr.FileType = that.arrContent[0].FileUrl ? 0 : 1
          fileArr.push(objarr)
        }
      }
      if (this.indexTab == 5) {
        //删除回收站的文件
        //  console.log('回收站',that.arrContent);
        // that.ajaxFn_list( that.indexTab - 1,0); //刷新
        that.delFn(Id == 'all' ? 'all' : Id)
      } else {
        ///删除
        this.$axios.post(
          9988,
          { UserId: this.userInfo.uid, FileList: JSON.stringify(fileArr) },
          (res) => {
            for (let k = 0; k < that.contentArr.length; k++) {
              for (let j = 0; j < that.arrContent.length; j++) {
                if (that.contentArr[k].Id == that.arrContent[j].Id) {
                  that.contentArr.splice(k, 1)
                }
              }
            }
            that.arrContent = []
            try {
              if (res.data.code == 0) {
                that.$message.error(res.data.msg)
                return false
              }
              that.maskBool = false
              that.operation_nav_val = ''
              that.$message.success(res.data.msg)
            } catch (error) {
              console.log('res.data错误')
            }
            let index
            let id
            if (that.navTitleArr.length == 0) {
              id = 0 //that.indexTab - 1;
            } else {
              index = that.navTitleArr.length - 1
              id = that.navTitleArr[index].Id
              that.maskBool = false
              that.typeFile = ''
              that.operation_nav_val = ''
            }
            that.ajaxFn_list(id, that.indexTab - 1) //刷新
            that.ajaxFn(id, that.indexTab - 1) //树改变
          }
        )
      }
    },
    //清空回收站
    delFn(id) {
      let that = this
      that.spinning = true
      this.$axios.post(9987, { UserId: this.userInfo.uid, Pid: id }, (res) => {
        that.spinning = false
        if (res.data.code == 0) {
          that.$message.error(res.data.msg)
        } else {
          that.$message.success(res.data.msg)
          if (id == 'all') {
            that.contentArr = []
            that.imgContentArr = []
          } else {
            that.tabFn(4, 'del') //刷新回收站
          }
        }
      })
    },
    //权限设置只读或共享
    readChangePerson(value) {
      // console.log(`selected只读或者共享 ${value}`);
      this.readPerson = value
    },
    // editChangePerson(value) {
    //   // console.log(value,'可编辑范围');
    //   this.editPerson = value;
    // },
    //文件 重命名 提交的fun
    edit_name_Fn() {
      // console.log(this.arrContent[0]);
      let that = this //移动 ：位置
      let obj = {
        UserId: this.userInfo.uid,
        Id: this.arrContent[0].Id,
        Type: this.arrContent[0].FileUrl ? 0 : 1,
        CategoryName: '文件名',
        Category: this.arrContent[0].FileUrl ? 'FileName' : 'Name',
        Value: this.editName,
      }
      // console.log(indexOfFn(this.editName),indexOfFn(this.arrContent[0].Name));
      if (this.arrContent[0].Name.indexOf('.') < 0) {
        that.edit_details(obj)
      } else {
        if (indexOfFn(this.editName) == indexOfFn(this.arrContent[0].Name)) {
          that.edit_details(obj)
        } else {
          that.$confirm({
            title: '是否更改文件尾缀?',
            content: '修改后可能会出现未知的错误',
            okText: '确认',
            cancelText: '取消',
            onOk() {
              that.edit_details(obj)
            },
            onCancel() {
              // console.log('Cancel');
            },
          })
        }
      }
    },
    backPosFn(obj) {
      // console.log('还原',obj);
      this.table_ul_pos.top = '0px' //(table控制ul操作显/隐)
      this.maskBool = false
      this.operation_nav_val = ''
      let that = this
      this.$axios.post(
        9986,
        { UserId: this.userInfo.uid, Id: this.arrContent[0].Id },
        (res) => {
          if (res.data.code == 0) {
            this.$message.error(res.data.msg)
            return false
          }
          this.maskBool = false
          // this.typeFile = '';
          this.operation_nav_val = ''
          this.$message.success(res.data.msg)
          that.tabFn(4)
          // this.ajaxFn_list(id,this.indexTab-1);//刷新
        }
      )
    },
    // 预览时 加星标
    lookOpeation(type, id, list) {
      let that = this
      let num = 1
      if (that.arrContent.length != 0) {
        for (let i = 0; that.arrContent.length; i++) {
          if (that.arrContent[i].Id == id) {
            num++
          }
        }
      }
      if (num == 1) {
        that.arrContent = []
        that.arrContent.push(list)
      }
      if (type == 'star') {
        that.starFn('预览处理星标', id, list)
      }
      if (type == 'del') {
        that.del_fileFn('预览时删除', id, list)
      }
    },
    starFn(obj, id, list) {
      // console.log(obj)星标
      // console.log(id, list);
      let that = this
      let fileArr = []
      that.arrContent.push(list)
      // console.log(this.arrContent)
      let arr = uniqueArray(that.arrContent, 'Id')
      that.arrContent = []
      that.arrContent = arr
      // console.log(that.arrContent);
      if (that.arrContent.length > 1) {
        for (let i = 0; i < that.arrContent.length; i++) {
          if (that.arrContent[i].IsStar != 1) {
            let objarr = {}
            objarr.Id = that.arrContent[i].Id
            objarr.FileType = 0
            fileArr.push(objarr)
          }
        }
      } else {
        // console.log(this.arrContent)
        let objarr = {}
        objarr.Id = that.arrContent[0].Id
        objarr.FileType = that.arrContent[0].IsStar ? 0 : 1
        fileArr.push(objarr)
      }
      // console.log(fileArr)
      if (fileArr.length == 0) {
        that.$message.success('所选为已加星标')
        return false
      }
      this.$axios.post(
        9985,
        { UserId: this.userInfo.uid, FileList: JSON.stringify(fileArr) },
        (res) => {
          // console.log(res);
          // console.log(that.arrContent);
          if (res.data.code == 0) {
            that.$message.error(res.data.msg)
            return false
          }
          that.maskBool = false
          // that.typeFile = '';
          that.operation_nav_val = ''
          that.$message.success(res.data.msg)
          // console.log(that.arrContent, that.indexTab);
          if (that.indexTab > 2) {
            that.tabFn(that.indexTab - 1)
          } else {
            that.ajaxFn_list(that.arrContent[0].Pid, that.indexTab - 1) //刷新
          }
        }
      )
    },
    operation_nav(val) {
      //切换table icon的操作栏
      this.operation_nav_val = val
      this.maskBool = true
      this.visible2 = false
    },
    //创建文件夹
    onSearch(val) {
      // console.log(val);
      if (!val) {
        val = '无标题文件夹'
      }
      let that = this
      let index
      let id
      if (this.navTitleArr.length == 0) {
        id = 0
      } else {
        index = this.navTitleArr.length - 1
        id = this.navTitleArr[index].Id
      }
      let numIndex
      if (this.userInfo.partnerType == 1) {
        numIndex = 1
      } else {
        numIndex = this.indexTab - 1
      }
      this.$axios.post(
        9984,
        { UserId: this.userInfo.uid, Pid: id, IsPrivate: numIndex, Name: val },
        (res) => {
          if (res.data.code == 1) {
            that.ajaxFn_list(id, that.indexTab - 1, 0) ///刷新
            that.ajaxFn(id, that.indexTab - 1)
            that.visible1 = false
            that.$message.success(res.data.msg)
          } else {
            that.$message.error(res.data.msg)
          }
        }
      )
    },
    //复制链接
    onCopy() {
      this.$message.success('复制成功')
    },
    onError() {
      this.$message.console.error('复制失败')
    },
  },
}
</script>
<style lang="less" scoped>
.FilesBox {
  position: relative;
  background: #fff;
  border-radius: 4px;

  display: flex;
  overflow-y: auto;
  user-select: none;
  font-size: 14px;
  color: #666666;
  .mr-2 {
    margin-right: 16px;
  }
  .bgHover {
    background-color: #f8f9fa;
  }
  th.column-money,
  td.column-money {
    text-align: right !important;
  }
  .border-r-4 {
    text-indent: 30px;
  }
  .title {
    color: #333333;
    font-size: 18px;
  }
  .link-label {
    color: #333333;
    font-size: 18px;
    display: inline-block;
    height: 100%;
    text-indent: 0px;
  }
  .gengduo {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    text-align: center;
    line-height: 36px;
    &:hover {
      background-color: #f8f9fa;
    }
  }
  .link-label:hover {
    cursor: pointer;
    color: #333333;
  }
  .input_box {
    height: 38px;
    border-radius: 20px;
    padding: 0 20px;
    border: 1px solid #eeeeee;
    background: #f8f9fa;
    margin: 10px 18px;
    display: inline-block;
    position: relative;
    input {
      width: 180px;
      height: 100%;
      background: transparent;
      border: 0px solid transparent;
      outline: none;
    }
    .btn {
      position: absolute;
      right: 16px;
      top: 9px;
      font-size: 18px;
    }
  }
  .create_tabs_right {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1100;
    width: 280px;
    color: #333333;
    font-size: 16px;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 78, 0.1);
    border-radius: 8px;
    text-indent: 50px;
    background: #fff;
    padding: 8px 2px;
    li {
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      position: relative;
    }
  }
  .create_file {
    // height: 112px;
    line-height: 112px;
    position: relative;
    // overflow: hidden;
  }

  .file_button {
    display: block;
    border-radius: 4px;
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    width: 80%;
    height: auto;
    padding: 5px 0;
    margin: 26px auto;
  }

  ul {
    color: #333333;
    margin: 0px;
    padding: 0px;
  }

  ul .lis {
    // height: 44px;
    line-height: 44px;
    list-style: none;
    cursor: pointer;
    // border-left: 4px solid transparent;
  }

  ul li:hover .border-r-4 {
    background-color: #f8f9fa;
  }

  .file-title-box {
    // height: 44px;
    line-height: 44px;
    display: flex;
    justify-content: space-between;
    text-indent: 18px;
    color: #333333;
    .mr-2 {
      cursor: pointer;
    }
    // .iconfont{
    //     font-size: 20px;
    // }
  }
  .border-files {
    border: 1px solid #e0e1e2;
  }
  .btn-file {
    height: 48px;
    line-height: 48px;
    margin-right: 15px;
    margin-top: 18px;
    border-radius: 4px;
    display: inline-block;
    overflow: hidden;
    position: relative;
    .files_name {
      width: 184px;
      vertical-align: middle;
      font-size: 12px;
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-left: 12px;
      text-align: left;
      margin-top: -2px;
    }
  }
  //文件list
  .img-file {
    margin-right: 16px;
    margin-top: 18px;
    border-radius: 4px;
    width: 236px;
    height: 216px;
    overflow: hidden;
    display: inline-block;
    position: relative;
    vertical-align: top;
    //  display:inline-block;
  }
  .imgLook {
    //  position:absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    z-index: 220;
  }
  .mask {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 100%;
    /* height: 100vh; */
    overflow: hidden;
    z-index: 200;
  }

  table {
    width: 100%;
    // margin-left: 1%;
    text-indent: 20px;
    border: 1px solid #e0e0e0;
    /* background: #ffffff; */
    // margin-top: 50px;
  }

  thead {
    background-color: #f8f9fa;
    height: 54px;
    line-height: 54px;
  }

  tr {
    height: 54px;
    line-height: 54px;
    cursor: pointer;
    // td{position: relative;}
  }

  tr:hover {
    background-color: #f8f9fa;
  }
  .create_tabs {
    position: absolute;
    // left: 230px;
    // top: 20px;
    z-index: 210;
    border: 1px solid #e1e2e3;
    background: #ffffff;
    border-radius: 8px;
    padding: 6px 6px;
    text-indent: 10px;
    line-height: 30px;
    width: 182px;
  }
  th:nth-child(1),
  td:nth-child(1) {
    text-align: center;
    text-indent: 0px;
  }

  /// 操作记录，详情
  .file_details {
    position: fixed;
    // top: 237px;
    right: 25px;
    z-index: 1010;
    padding-bottom: 8px;
    background: #ffffff;
    width: 320px;
    border: 1px solid #e0e1e2;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 78, 0.1);
    // height: 100%;
    // min-height: 700px;
    user-select: none;
    i {
      font-size: 24px;
      columns: #999999;
      vertical-align: middle;
    }
    .details_file_name {
      height: 50px;
      line-height: 50px;

      .title_name {
        display: inline-block;
        width: 288px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 50px;
      }
    }
    .details_file_tabs {
      display: flex;
      height: 56px;
      line-height: 56px;
      font-size: 16px;
      text-align: center;
      justify-content: center;
      & > div {
        width: 100%;
      }
      span {
        display: inline-block;
        width: 100px;
        height: 56px;
      }
    }
    .img_icon_max {
      margin-top: 80px;
    }
    .details_info {
      overflow-y: auto;
      .imgBox {
        line-height: 215px;
        text-align: center;
        img {
          max-width: 302px;
          max-height: 214px;
        }
      }
      .details_info_box {
        margin-top: 14px;
        width: 290px;
        margin-left: 19px;
        & > div {
          display: inline-block;
          width: 200px;
          color: #333333;
          margin-top: 10px;
          vertical-align: top;
          &:nth-child(2n + 1) {
            width: 80px;
            color: #666666;
          }
        }
      }
    }
    .details_record {
      padding: 10px 14px;
      overflow: auto;
      .details_record_box {
        & > div {
          margin-bottom: 10px;
        }
        img {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          margin-right: 10px;
          //   vertical-align: middle;
        }
      }
    }
  }
  //。。。更多
  .create_tabs2 {
    position: absolute;
    top: 40px;
    width: 160px;
    // border: 1px solid red;
    font-size: 14px;
    z-index: 220;
    background: #fff;
    border: 1px solid #e0e1e2;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 78, 0.1);
    li {
      line-height: 32px;
    }
  }
  //预览
  .look_wrap {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 1000;
    width: 100%;
    height: 90px;
  }
  .look_box {
    display: inline-table;
    position: relative;
    text-align: center;
    width: 100%;
    height: 100vh;
    line-height: 100vh;
    // margin: 50px 50%;
    //  margin-top: 10vh;
    //  margin-left: 10%;
  }

  // .prevNext{
  //     position: absolute;
  //     top: 50%;
  //     transform: translateY(-50%);
  //     font-size: 50px;
  //     // color: #fff;
  // }
  // .btn_prev{left: 20px;}
  // .btn_next{right: 20px;}
  .progress-file {
    padding-bottom: 6px;
    position: fixed;
    z-index: 240;
    bottom: 20px;
    right: 20px;
    border: 1px solid #f1f1f1;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 4px 6px 6px #e1efef;
    overflow: hidden;
    .progress-box {
      width: 254px;
      max-height: 300px;
      margin-left: 10px;
      overflow-y: auto;
      position: relative;
      .progress-over {
        display: inline-block;
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .progressNum {
        width: 14%;
        display: inline-block;
        text-align: center;
      }
    }
  }
}

/// 侧边栏(右边移出)
.ant-drawer-body {
  padding: 0px;
}
.line {
  border-bottom: 1px solid #e0e1e2;
}

.drawer_file_name {
  color: #333;
  font-size: 18px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.drawer_file_input_box {
  font-size: 14px;
  color: #666666;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.navList {
  padding: 0px;
  color: #333333;
  li {
    // height: 44px;
    line-height: 32px;
    text-indent: 4px;
    list-style: none;
    cursor: pointer;
    list-style: none;
  }
}
ul li:hover {
  background-color: #f8f9fa;
}
.nav_fileBox {
  // height: 100%;
  border-right: 1px solid #e0e1e2;
  overflow: hidden;
  width: 250px;
}
// 右边盒子
.right_box{
  flex: 1;
}

// 新建按钮
.input_file {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
}
.iconfont {
  margin-right: 10px;
  font-size: 20px;
  // vertical-align: middle
}
// 文件list

.box-file-look {
  width: 100%;
  line-height: 164px;
  overflow: hidden;
  text-align: center;
  overflow: hidden;
}
.ant-dropdown-menu-item {
  color: #333333;
}
.info-file {
  border-top: 1px solid #e0e1e2;
  width: 100%;
  line-height: 48px;
  height: 50px;
}
.info-file .iconfont,
td .iconfont {
  font-size: 28px;
  vertical-align: sub;
  margin-right: 6px;
}

.name-file {
  font-size: 12px;
  // color: #333333;
  display: inline-block;
  width: 156px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 24px;
  line-height: 20px;
  vertical-align: middle;
  text-align: left;
}
.look_top {
  position: absolute;
  top: 10px;
  left: 50%;
  right: 0px;
  // text-indent: 20px;
  font-size: 18px;
  line-height: 50px;
  // color: #fff;
  font-size: 18px;
}
</style>
